import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor() { }

  readonly loading$ = new BehaviorSubject(false);

  loading = this.loading$.asObservable();

  setLoading(inprogess: boolean) {
    this.loading$.next(inprogess);
  }
}
