import { Component, OnInit } from '@angular/core';
import { AuthService, AuthStates,FlexService } from 'csx-flex-ng-oidc';
import { wrQuestions } from './category';
import {MenuItem} from 'primeng/api';
import { Float } from 'aws-sdk/clients/ec2';
import { Greengrass } from 'aws-sdk';
import { String } from 'aws-sdk/clients/apigateway';
import { SubmitInspectionAnswer } from '../calendar-day-inspection/models/submit-inspection-answer.model';
import { SubmitInspection } from '../calendar-day-inspection/models/submit-inspection.model';
import { AppLocInspService } from '../../services/app-loc-insp/app-loc-insp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'work-report-inspection',
  templateUrl: './work-report-inspection.component.html',
  styleUrls: ['./work-report-inspection.component.scss']
})
export class WorkReportInspectionComponent implements OnInit {

  activeContainer: string = "default";
  testQuestionsData: any = {};
  questionsData: any = wrQuestions;
  categories: any = [];
  questionsByCategories: any = {};
  inspectionAnswers: any = {};
  answersArray: any = [];
  userId: string;
  locoInit: string='CSXT';
  locoNum: string='000011';
  public inspDate: Date;
  public trainId: String;
  public location: String;
  public maxDateValue: Date = new Date();
  RadioOptions: any[];
  speedOptions: any[];
  public radioOp: String;
  public speedOp: String;
  public bpPressure: Float;
  public mrPressure: Float;
  public serial: String;
  public tCol: Boolean=false;
  public locoCol: Boolean=false;
  public unit : String;
  public Loco1: String;
  public locoCol1: Boolean=false;
  public LocoComment1: String;
  public Loco2: String;
  public locoCol2: Boolean=false;
  public LocoComment2: String;
  public Loco3: String;
  public locoCol3: Boolean=false;
  public LocoComment3: String;
  public Loco4: String;
  public locoCol4: Boolean=false;
  public LocoComment4: String;
  public Loco5: String;
  public locoCol5: Boolean=false;
  public LocoComment5: String;
  public Loco6: String;
  public locoCol6: Boolean=false;
  public LocoComment6: String;
  public locationCol: Boolean=false;
  public bpPressCol: Boolean=false;
  public mrPressCol: Boolean=false;
  public milesOff: number;
  public mph: number;
  public milesCol: Boolean=false;
  public mphCol: Boolean=false;
  public disableSubmit: Boolean=true;
  authSubscription: Subscription;
  public displaySuccess: boolean=false;


 

  constructor(private Flex: FlexService, 
    private router: Router,
    private authService: AuthService,
    public appLocInspService: AppLocInspService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,) { 
    this.RadioOptions = [
      {label: 'Good', value: 'Good', icon: ''},
      {label: 'Reported', value: 'Reported', icon: ''},
      {label: 'Not Equipped', value: 'Not Equipped', icon: ''}
    ];
    this.speedOptions = [
      {label: 'Accurate', value: 'Accurate', icon: ''},
      {label: 'Slow', value: 'Slow', icon: ''},
      {label: 'Fast', value: 'Fast', icon: ''}
    ];

  }

  ngOnInit(): void {
    this.getWRQuestions();
    this.initializeAndGetData();
    this.displaySuccess = false;
    this.appLocInspService.locoInfo$.subscribe(val => {
      if(val) {
        this.unit = val.locoInit+val.locoNumber;       
      }
    });
  }

   // FIXME this should be handled by the module it lives in and a route guard
   initializeAndGetData(): void {
    this.authSubscription = this.authService
      .isAuthorized()
      .subscribe(authStatus => {
        if (authStatus === AuthStates.AUTHORIZED) {
          if (this.Flex.user) {
            this.userId = this.Flex.user.id;
          }
        }
      });
  }
  getWRQuestions() {
    this.categorizeQuestions(this.questionsData);
  }

  categorizeQuestions(questionsData) {
    let addedCategories = {};
    for(let i=0; i < questionsData.length; i++) {
      // let answerJson = {
      //   'id': questionsData[i].CLIP_QUESTIONSID,
      //   'category': questionsData[i].CSXCATEGORY,
      //   'answer': '',
      //   'comment': ''
      // }

      // this.inspectionAnswers[questionsData[i].CLIP_QUESTIONSID] = answerJson;

      if (addedCategories[questionsData[i].CSXCATEGORY]) {
        continue;
      }
      addedCategories[questionsData[i].CSXCATEGORY] = true;
      let categoryToAdd = {
        'CSXCATEGORY' : questionsData[i].CSXCATEGORY,
        'CSXDESCRIPTION' : questionsData[i].CSXDESCRIPTION
      }

      this.categories.push(categoryToAdd)

    }

     //creates an object of arrays. The key for each array is the CSXCATEGORY ID
    //i.e. { 1 : [<array of questions with CSXCATEGORY of 1>], 2 : [<array of questions with CSXCATEGORY of 2>]}
    this.questionsByCategories = questionsData.reduce((previous, current) => {
      previous[current['CSXCATEGORY']] = [...previous[current['CSXCATEGORY']] || [], current];
      return previous;
    }, {})

    ////console.log(this.questionsByCategories);
    ////console.log(this.categories);
  }

  trainIdVal(){
    var teststring: String='test';
    var test1: RegExp;
    test1 = new RegExp(/^[A-Za-z]{1}[0-9\s]{5}$/);
    //console.log("*************"+this.trainId);
    if(test1.test(this.trainId) && this.trainId.length==6){
      this.tCol=false;
    }else{
      this.tCol=true;
    }
    //console.log("*************"+this.tCol);
  }

  locoVal(event: Event){
    //console.log("*************Id"+(event.target as HTMLInputElement).id);
    var id=(event.target as HTMLInputElement).id;
    var value=(event.target as HTMLInputElement).value;
    var test1: RegExp;
    var test2: RegExp;
    var test3: RegExp;
    var test4: RegExp;
    var locoFinal: String;
    var result: Boolean;
    test1 = new RegExp(/^[A-Za-z]{4}[0]{2}[0-9]{4}$/);
    test2= new RegExp(/^[A-Za-z]{1,4}[0]{0,2}[0-9]{1,4}$/);
    test3= new RegExp(/^[0]{0,2}[0-9]{1,4}$/);
    test4=new RegExp(/^[A-Za-z]{1,4}\s{1,2}[0]{0,2}[0-9]{1,4}/);
    locoFinal=value;   
    //console.log("*************tvalue"+value);
    if(value.length==0 && id!='104'){
      result=false;
      //console.log("*************null value"+value);
    }else if(value!=null && test1.test(value) && value.length==10){
      result=false;
      //console.log("*************test11"+this.tCol);
    }else if(value!=null && test2.test(value)){      
      result=false;
      var locNUmber=value.match(/[0-9]{1,6}/).toString();
      locNUmber=locNUmber.padStart(6 , '0');
      var initial=value.match(/[A-Za-z]{1,4}/).toString();      
      if(initial=='CSX'){
        initial='CSXT'
      }
      if(initial.length<4){
        initial=initial.padEnd(4 , ' ')
      }
      locoFinal=initial+locNUmber;
      //console.log("*************test222"+this.tCol+"^^^^^"+locNUmber.padStart(6 , '0')+initial);
    }else if (value!=null && test3.test(value)){
      result=false;
      var locNUmber=value.match(/[0-9]{1,6}/).toString();
      locNUmber=locNUmber.padStart(6 , '0');
      locoFinal='CSXT'+locNUmber;
      //console.log("*************test33"+this.tCol+"^^^^^"+locoFinal);
    }else if (value!=null && test4.test(value)){
      result=false;
      var locNUmber=value.match(/[0-9]{1,6}/).toString();
      locNUmber=locNUmber.padStart(6 , '0');
      var initial=value.match(/[A-Za-z]{1,4}/).toString();      
      if(initial=='CSX'){
        initial='CSXT'
      }
      if(initial.length<4){
        initial=initial.padEnd(4 , ' ')
      }
      locoFinal=initial+locNUmber;
      //console.log("*************test4"+this.tCol+"^^^^^"+locoFinal);
    }
    else{
      result=true;
      this.disableSubmit=true;
    }
    if(id=='104'){
      this.unit=locoFinal;
      this.locoCol=result;
    }else if (id=='Loco1'){
      this.Loco1=locoFinal;
      this.locoCol1=result;
    }else if (id=='Loco2'){
      this.Loco2=locoFinal;
      this.locoCol2=result;
    }else if (id=='Loco3'){
      this.Loco3=locoFinal;
      this.locoCol3=result;
    }else if (id=='Loco4'){
      this.Loco4=locoFinal;
      this.locoCol4=result;
    }else if (id=='Loco5'){
      this.Loco5=locoFinal;
      this.locoCol5=result;
    }else if (id=='Loco6'){
      this.Loco6=locoFinal;
      this.locoCol6=result;
    }
    //console.log("*************-result"+result);
  }

  locationVal(){
    if(this.location.length>40){
      this.locationCol=true;      
      //console.log("*************location color"+this.locationCol);
    }else{
      this.locationCol=false;
    }

  }

  bpPressureVal(){
    if(this.bpPressure.toString().length>3 || isNaN(this.bpPressure)){
      this.bpPressCol=true;
      this.disableSubmit=true;
    }else{
      this.bpPressCol=false;
    }
  }

  mrPressureVal(){
    if(this.mrPressure.toString().length>3 || isNaN(this.mrPressure)){
      this.mrPressCol=true;
      this.disableSubmit=true;
    }else{
      this.mrPressCol=false;
    }
  }

  milesVal(){
    if(this.milesOff.toString().length>2 || isNaN(this.milesOff)){
      this.milesCol=true;
      this.disableSubmit=true;
    }else{
      this.milesCol=false;
    }
  }

  mphVal(){
    if(this.mph.toString().length>2 || isNaN(this.mph)){
      this.mphCol=true;
      this.disableSubmit=true;
    }else{
      this.mphCol=false;
    }
  }

  disableSubmitButton(){

    if (this.trainId==null || this.tCol || this.location==null || this.locationCol || this.inspDate==null || this.bpPressure==null
      || this.bpPressCol || this.mrPressure==null || this.mrPressCol || this.radioOp==null || this.serial==null || this.unit==null
      || this.locoCol || this.speedOp ==null || (this.speedOp!='Accurate' && (this.milesOff==null || this.mph==null || this.mphCol || this.milesCol ))
      || (this.Loco1!=null && (this.locoCol1)) || (this.Loco2!=null && (this.locoCol2)) || (this.Loco3!=null && (this.locoCol3))
      || (this.Loco4!=null && (this.locoCol4)) || (this.Loco5!=null && (this.locoCol5) || (this.Loco6!=null && (this.locoCol6)))
      ){
        //console.log('disabled');
      return true;
    }else{
      //console.log('enabled');
      return false;
    }

  }

  returnToLocoInsp(): void {
    // Submission success
    this.appLocInspService.setBoolForSubmitWRCompletion(this.displaySuccess);
    // route back to loco-insp with same loco details
    this.router.navigate([
      '/loco-insp',
      { locoInit: this.locoInit, locoNum: this.locoNum }
    ]);
  }
  // TODO implmement this
  submitReport(): void {
    if (!this.disableSubmitButton()) {
      // TODO figure out what exactly needs to be called with what
      this.loaderService.setLoading(true);
      this.submitInspection();
    } else {
      // TODO give feedback for why?
      //console.log('do nothing, the questions are incomplete');
    }
  }

  submitInspection(): void {
    // generate the parameters to invoke the procedure
    let parameters = [ [
       null,
        this.userId,
        (new Date()).toLocaleString(),
        this.unit,
       this.trainId,
        this.location,
        (new Date()).toLocaleString(),
        2   ] 
    ];

    // define procedure invocation
    let submitInspectionSqlInvocation = {
      adapter: 'sql_clip',
      procedure: 'submitInspectionSql',
      parameters: parameters
    };

    // define invocation options
    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      }
    };

    // invoke it!
    this.Flex.invokeProcedure(submitInspectionSqlInvocation, options).then(
      response => {
        if (
          response.invocationResult &&
          response.invocationResult.result &&
          response.status === 200
        ) {
          let inspectionId =
            response.invocationResult.result[0].CLIP_INSPECTIONSID;
          this.callSubmitInspectionAnswersSqlProcedure(
          //   categories,
             inspectionId,this
           );
        } else {
          this.loaderService.setLoading(false);
          // TODO implement failure path
          //console.log('submitInspection() if trap');
        }
      }
    );
  }

  callSubmitInspectionAnswersSqlProcedure(
    inspectionId: number,insp: any
  ): void {
    let jsonArray=[];
    
    //console.log('value&&&&(((((('+insp.unit);
    for (let i=100; i <108; i++) {	
      let jsonData = {};
		      	jsonData["id"] = i;
            jsonData["category"] = 0;
            if(i==100){
              jsonData["answer"]= insp.bpPressure;
            }else if (i==101){
              jsonData["answer"]= insp.mrPressure;
            }else if (i==102){
              jsonData["answer"]= insp.radioOp;
            }else if (i==103){
              jsonData["answer"]= insp.serial;
            }else if (i==104){
              jsonData["answer"]= insp.unit;
            }else if (i==105){
              jsonData["answer"]= insp.speedOp;
            }else if (i==106){              
              jsonData["answer"]= insp.milesOff;
            }else if (i==107){
                            jsonData["answer"]= insp.mph;
            }            
            if(jsonData["answer"]==undefined){
              jsonData["answer"]='';
            }
            jsonData["comment"] = "";
            jsonData["created"] =  new Date();
            jsonData["createdby"] = insp.userId;
            jsonData["inspectionid"] = inspectionId;
            jsonArray.push(jsonData);
		}


    for (let i=108; i <114; i++) {
		let	jsonData = {};
			jsonData["id"] = i;
      jsonData["category"] = 0;
      if(i==108){
        jsonData["answer"]= insp.Loco1;
        jsonData["comment"]=insp.LocoComment1;
      }else if (i==109){
        jsonData["answer"]= insp.Loco2;
        jsonData["comment"]=insp.LocoComment2;
      }else if (i==110){
        jsonData["answer"]= insp.Loco3;
        jsonData["comment"]=insp.LocoComment3;
      }else if (i==111){
        jsonData["answer"]= insp.Loco4;
        jsonData["comment"]=insp.LocoComment4;
      }else if (i==112){
        jsonData["answer"]= insp.Loco5;
        jsonData["comment"]=insp.LocoComment5;
      }else if (i==113){
        jsonData["answer"]= insp.Loco6;
        jsonData["comment"]=this.LocoComment6;
      }    
      if(jsonData["answer"]==undefined){
        jsonData["answer"]='';
      }
      if(jsonData["comment"]==undefined){
        jsonData["comment"]='';
      }
            
      jsonData["created"] = new Date();
      jsonData["createdby"] = insp.userId;
      jsonData["inspectionid"] = inspectionId;
      jsonArray.push(jsonData);
		}
    //console.log('jason******'+[JSON.stringify(jsonArray)]);

    let submitInspectionAnswersSqlInvocation = {
      adapter: 'sql_clip',
      procedure: 'submitInspectionAnswersSql',
      parameters: [JSON.stringify(jsonArray)]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      }
    };

    this.Flex.invokeProcedure(
      submitInspectionAnswersSqlInvocation,
      options
    ).then(response => {
      if (
        response.invocationResult &&
        response.invocationResult.result &&
        response.status === 200
      ) {
        this.displaySuccess = true;
        this.loaderService.setLoading(false);
      } else {
        this.loaderService.setLoading(false);
        // FIXME implement failure path
        //console.log('Implement behavior for failure submission', response);
      }
    });

  }
  getCategoryIcon(csxCategoryId: string): string {
    let iconClass: string;
    //console.log("catergoryid*****"+csxCategoryId);
   if(csxCategoryId=='1') {
   
        //console.log("in catergoryid*****"+csxCategoryId);
        iconClass = 'csx-train_caravan';
     }else if(csxCategoryId=='2')    { 
         iconClass = 'csx-repair_brake_shoes';
            }
            else if(csxCategoryId=='3')    { 
         iconClass = 'csx-common_panel';
    }
      else if(csxCategoryId=='4')    { 
        iconClass = 'csx-speed_high_acceleration';
    }
      else if(csxCategoryId=='5')    { 
        iconClass = 'csx-locomotive';
    }
    else {
        iconClass = 'csx-common_help';
    }
    //console.log("IconClass*****"+iconClass);
    return iconClass;
  }
  
}
