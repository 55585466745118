import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LcdiCategory } from '../models/lcdi-category.model';
import { LcdiQuestion } from '../models/lcdi-question.model';
import { MenuItem } from 'primeng/api';
import { AppLocInspService } from '../../../services/app-loc-insp/app-loc-insp.service';
import { FlexService } from 'csx-flex-ng-oidc';
import { LoaderService } from '../../../services/loader/loader.service';

declare const navigator: any;

interface FileInfo {
  file: File;
  userId: string;
}

@Component({
  selector: 'question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss']
})
export class QuestionDetailComponent implements OnInit {
  @Input() activeContainer: string;
  @Input() category: LcdiCategory;
  @Output() onAnswerSelected: EventEmitter<LcdiCategory> = new EventEmitter();
  @Output() onAnswerUnselected: EventEmitter<LcdiCategory> = new EventEmitter();
  @Output() onImageUpload: EventEmitter<any> = new EventEmitter();
  // PrimeNg never bothered defining a model
  private questionOptions: any[];
  private items: MenuItem[];
  fileInfo: FileInfo;
  fileUploadQuestionId: number;
  imageUrls: any[]=[];

  constructor(private Flex: FlexService, private appLocInspService: AppLocInspService,private loaderService: LoaderService) {
    this.questionOptions = [
      { label: 'Yes', value: 'Y', icon: 'csx-common_check' },
      { label: 'No', value: 'N', icon: 'csx-common_remove_circle' },
      { label: 'Not Equipped', value: 'NE', icon: 'csx-common_ban' }
    ];

    this.items = [
        {label: 'From Camera', icon: 'pi pi-camera', command: () => {
            this.cameraClick();
        }},
        {label: 'Photo Library', icon: 'pi pi-images', command: (x) => {
          this.clickFileUpload();
        }}
    ];

    this.fileInfo = {file: null, userId: this.Flex.user.id};
  }

  ngOnInit(): void {}

  answerSelected(event, question: LcdiQuestion): void {
    // update the question with the provided answer and emit the entire category with it
    let foundIndex = this.category.lcdiQuestions.findIndex(
      obj => obj.questionId === question.questionId
    );
    this.category.lcdiQuestions[foundIndex].answer = event.value;
    this.onAnswerSelected.emit(this.category);
  }

  questionIsAnswered(question: LcdiQuestion): boolean {
    let foundIndex = this.category.lcdiQuestions.findIndex(
      obj => obj.questionId === question.questionId
    );

    return this.category.lcdiQuestions[foundIndex].answer ? true : false;
  }

  unselectAnswer(question: LcdiQuestion): void {
    // update the question with the provided answer and emit the entire category with it
    let foundIndex = this.category.lcdiQuestions.findIndex(
      obj => obj.questionId === question.questionId
    );
    this.category.lcdiQuestions[foundIndex].answer = undefined;
    this.onAnswerUnselected.emit(this.category);
  }

  translateAnswerValue(answerValue: string): string {
    let displayAnswer: string;
    if (answerValue === 'Y') {
      displayAnswer = 'Yes';
    } else if (answerValue === 'N') {
      displayAnswer = 'No';
    } else if (answerValue === 'NE') {
      displayAnswer = 'Not Equipped';
    } else {
      console.log('translateAnswerValue() else if trap');
    }
    return displayAnswer;
  }

  getFile(e,question: LcdiQuestion): void{
    
    console.log('Chosen file : ' + e.currentTarget.files[0].name);
    // Send to AWS S3
    this.fileInfo.file = e.currentTarget.files[0];
    this.sendFileToAWS(e.currentTarget.files[0].name,question);    
  }

  cameraClick() {
    var instance = this;
    var photo;
    return new Promise(function (resolve, reject) {
      try {
        if (navigator.camera == undefined) {
          alert("navigator.camera == undefined");
        } else {
          navigator.camera.getPicture(
            (imageUri) => {
              photo = imageUri;
              instance.fileInfo.file = photo;
              let tmpFileName = instance.appLocInspService.locomotiveDetail.locoInit+instance.appLocInspService.locomotiveDetail.locoNumber + new Date().toString();
              this.sendFileToAWS(tmpFileName,this.fileUploadQuestionId);
            },
            (error) => {
              alert("Unable to obtain picture: " + error);
            },
            {
              quality: 50,
              destinationType: navigator.camera.DestinationType.DATA_URL
            }
          )
        }
      } catch (e) {
        alert(e);
      }
    });
  }

  clickImageDropdown(questionId) {
    this.fileUploadQuestionId = questionId;
  }

  clickFileUpload() {
    document.getElementById('file-upload-'+ this.fileUploadQuestionId).click();
    this.loaderService.setLoading(true);
  }
  sendFileToAWS(fileName: string,question: LcdiQuestion) {
    
    const formData: FormData = new FormData();
    var rescCtype: any;
    var formType: string = 'CLIP_INSPECTIONS';
    formData.append("file", this.fileInfo.file, fileName);
    this.appLocInspService.uploadFileForReportIncident(formData, fileName).subscribe(response => {
      console.log(response);
      
      var images=[question.questionId,response.presignedurl,fileName];
      this.imageUrls.push(images);
      this.onImageUpload.emit(images);
      console.log('images'+this.imageUrls[2]);
      this.loaderService.setLoading(false);
      //presignedurl
      // below hard coded incident number and question id, has to be handled //      
    }, (err) =>{ 
      console.log(err);
      this.loaderService.setLoading(false);
    }
    );
    
  }

}
