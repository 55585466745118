export const LocomotiveIncidentMap : Map<string, string> = new Map(
    [
        ["AB1", "Acoustic Bearing Defect 1"],
        ["AB2", "Acoustic Bearing Defect 2"],
        ["AB3", "Acoustic Bearing Defect 3"],
        ["AB4", "Acoustic Bearing Defect 4"],
        ["AB5", "Acoustic Bearing Defect 5"],
        ["AB6", "Acoustic Bearing Defect 6"],
        ["ABP", "Air Brake Problems/Air Leak"],
        ["ACD", "Air Compressor Defect/Air Pressure Problem"],
        ["ACF", "Analog Circuit Failure"],
        ["ACO", "Authorized Hard Cut-out"],
        ["AEI", "Defective AEI Tags"],
        ["AFI", "Air Flow Indicator Inspection Due"],
        ["AGD", "Axle Gear Defect"],
        ["ALD", "Alerter Defect"],
        ["ALP", "Alternator/Blower Problem"],
        ["ANT", "Faults associated with PTC Antennas"],
        ["APP", "Air problem and /or air leaks"],
        ["APU", "Auxiliary Power Unit"],
        ["ARD", "Air Conditioner Defect"],
        ["ATP", "Asset Tracking Problem"],
        ["ATS", "Asset tracking system"],
        ["BAS", "Identified for BA Storage"],
        ["BD1", "Hot Bearing Level 1 Imperative Response"],
        ["BD2", "Hot Bearing Level 2 Imperative Response"],
        ["BD3", "Hot Bearing Level 3 Imperative Response"],
        ["BD4", "Hot Bearing Level 4 Imperative Response"],
        ["BD5", "Hot Bearing/Wheel Defect 5"],
        ["BD6", "Hot Bearing/Wheel Defect 6"],
        ["BHD", "Crossing bell/horn defect"],
        ["BKS", "Missing/Broken cut-out switch seals"],
        ["BPP", "Invalid brake pressure"],
        ["BRD", "Brake Shoe/Rigging/Hand Brake Defect"],
        ["C19", "Confirmed Covid-19 individual occupied locomotive"],
        ["CAD", "Accident/Outside Party Rebillable(Call Mech Desk To Add)"],
        ["CB1", "Combo Defect 1"],
        ["CB2", "Combo Defect 2"],
        ["CB3", "Combo Defect 3"],
        ["CB4", "Combo Defect 4"],
        ["CB5", "Combo Defect 5"],
        ["CB6", "Combo Defect 6"],
        ["CCP", "Crankcase Protector Problems"],
        ["CHD", "Cab Heater Defect"],
        ["CHM", "Crash Hardened Memory Module"],
        ["CMM", "Communications Management Unit Issue"],
        ["CPU", "CPU voting logic, synchronization errors, and software faults"],
        ["CRD", "Cab Door/Window/Seat/Floor/Ice Box Defect"],
        ["CRP", "Computer Related Problem"],
        ["CSD", "Cab Seat Defect"],
        ["CSI", "Cab Signal Interface Issue"],
        ["CSP", "Charging System Problem"],
        ["CSR", "Calibrate Speed Recorder"],
        ["CWI", "Cracked Wheel Inspection"],
        ["D23", "Past Due 23 Day Lube"],
        ["D30", "Past Due 30 Day Lube"],
        ["D46", "Past Due 46 Day Lube"],
        ["D84", "Schedule 184 Day Maintenance Due"],
        ["D92", "Schedule 92I Maintenance Due"],
        ["DAA", "Due Annual Air"],
        ["DAB", "Due Airbrake Changeout"],
        ["DAD", "Dead at Destination"],
        ["DAM", "Due Annual Load Meter"],
        ["DBP", "Dead Battery/Charging System Problems"],
        ["DCP", "Draft Gear/Coupler"],
        ["DDD", "Derailment Damage Defect"],
        ["DFP", "Dynamic Brake Fluctuation"],
        ["DHB", "Due Handbrake Inspection"],
        ["DIO", "Discrete Input Output Failure"],
        ["DLD", "Crossing/Warning Light(s) Defective"],
        ["DNM", "Do Not Move"],
        ["DNP", "Dynamic Brake Problems"],
        ["DOC", "Degraded Oil Change"],
        ["DOP", "DC Link/Overvoltage Problem"],
        ["DPF", "Distributed Power Failure (Specify in comments failure, Lead or Remote)"],
        ["DPM", "Dropping Power in Motoring"],
        ["DRP", "Data Radio Problem"],
        ["DSF", "Down To Save Fuel"],
        ["DSL", "Due Scheduled Traction Motor Lubrication"],
        ["DST", "OPERATIONS CENTER SPECIAL RECALL"],
        ["DSV", "Scheduled Servicing"],
        ["DUP", "Dynamic Brake Underload"],
        ["DVM", "LDVR Removed Needs To Be Replaced"],
        ["DVP", "VSWR Out of Range"],
        ["DVR", "Digital Video Download Requested"],
        ["DVX", "Digital Video Defect/Damaged"],
        ["DWP", "DWORS Related Problem"],
        ["EAB", "Failure between the electronic airbrakes and the I-ETMS."],
        ["EBI", "Electronic Brake Interface Failure"],
        ["ECD", "Engine Component Defective"],
        ["EME", "PTC Emergency Circuit Failure"],
        ["ENR", "Unusual or Excessive Noise"],
        ["ERD", "Event Recorder Download Requested"],
        ["ERP", "Exhaust Related Problem"],
        ["ESD", "Engine Shutting Down"],
        ["EVR", "Event Recorder Problem"],
        ["FAC", "Faults Associated with the ACC"],
        ["FAQ", "Frequently Asked Question"],
        ["FDP", "Fire Damage/Electrical or Mechanical"],
        ["FHT", "Fail Horn Test-Repair/Retest next Horn S"],
        ["FLP", "Flange Lubrication Problem"],
        ["FMS", "Fuel Monitoring System Problems"],
        ["FRA", "FRA Defects/Repair At Next Facility"],
        ["FSC", "Fueling Sensor Components"],
        ["FSP", "Fuel System Problem"],
        ["FSX", "Flat Spots-Serviceable"],
        ["FWD", "Flat Wheel Defect"],
        ["FZD", "Freeze Damage"],
        ["GPC", "GPS Communication Related Defect"],
        ["GPD", "GPS Communication Related Defect"],
        ["GPF", "GPS Fuel Related Defect"],
        ["GRP", "Ground Protection Relay/Diode Problems"],
        ["GUR", "GURU Valve Open"],
        ["HCD", "Hump Control Defect"],
        ["HJD", "Hot Journal Defect"],
        ["HLD", "Head Light/Crossing Light Defect"],
        ["HTD", "Head Of Train Device Defect"],
        ["I31", "Schedule 31 Day Inspection Due"],
        ["I33", "Schedule 33 Day Inspection Due"],
        ["I92", "92I Inspection Due Within 5 Days"],
        ["IOC", "Input/Output Concentrator failure."],
        ["IWA", "Idler Wheel Applied"],
        ["LAB", "Lab Alert/Immediate Response Required"],
        ["LCD", "Locomotive Collision Damage (Call Mech Desk To Add)"],
        ["LEP", "Loading Problems"],
        ["LFP", "Loading Forward Only"],
        ["LID", "Failure/Faluts of the Locomotive ID setting and the EEPROM."],
        ["LIG", "Faults resulting from a LIG communications loss"],
        ["LIP", "Lighting Problem"],
        ["LKP", "Oil Or Water Leakage Problem"],
        ["LLA", "LAT-LON No Analog Data"],
        ["LLD", "LAT-LON No Discrete Input Data"],
        ["LLF", "LAT-LON No Fuel Data"],
        ["LLG", "LAT-LON No GPS/Cell Data"],
        ["LLI", "LAT-LON No Impact Data"],
        ["LLP", "Low Lube Oil Problem/Oil Leak"],
        ["LLX", "LAT-LON No Power"],
        ["LMP", "Lateral Motion Problem"],
        ["LNP", "Not Loading At All"],
        ["LOF", "Low on Fuel"],
        ["LOP", "Overloading/Bogging Down"],
        ["LPG", "Operations Center Special Recall"],
        ["LRP", "Loads In Reverse Only"],
        ["LRQ", "Lab Sample Request"],
        ["LRT", "Alerter - Faults resulting from the alerter not working"],
        ["LUP", "Underloading Problem"],
        ["LWP", "Low Water Pressure Problem/Water Leak"],
        ["MMM", "Operations Center Information Request"],
        ["MOD", "Shop For Modification, I Or B Item"],
        ["MUD", "Trainline Problem/Air Or Electrical"],
        ["NMR", "Not Motoring with Engine Running"],
        ["NMS", "Not Motoring Engine Shutdown"],
        ["NRP", "Noise, Unusual or Excessive"],
        ["NRX", "No Radio"],
        ["NSF", "Faults/Failures resulting from new software load"],
        ["OFF", "Out Of Fuel Follow-Up"],
        ["OFL", "CALL MECHANICAL DESK - OFFLINE INCIDENT"],
        ["OOF", "Out Of Fuel"],
        ["OOP", "Oil Out Stack Problem"],
        ["OPK", "Faults From a Bad OPK"],
        ["OSP", "Overspeed Trips Problem"],
        ["OTA", "OTA Ready to Install"],
        ["OTH", "Other remediation. Please specify in the details."],
        ["P92", "PERFORMED 92/122 DAY MAINTENANCE"],
        ["PCC", "Power Circuit/Control Problem"],
        ["PCD", "Positive Train Control Communication Defect"],
        ["PCP", "PC Related Problem"],
        ["PCS", "Departure test failure to activate the locomotive PCS."],
        ["PDS", "Faults related to the PTC Computer Display Unit"],
        ["PEN", "PTC Penalty Circuit Failure"],
        ["PHF", "PTC Horn Circuit Failure"],
        ["PID", "Positive Train Control Informational Defect"],
        ["PIJ", "Personal Injury Inspection"],
        ["PIT", "PERFORMANCE IMPROVEMENT TEAM"],
        ["PMB", "Predictive Maintenance Battery"],
        ["PPA", "Pinpoint Asset Tracking System Problem"],
        ["PPE", "PINPOINT ERM/APU Problem"],
        ["PPF", "PINPOINT Fuel Related Problem"],
        ["PPL", "PINPOINT GPS Location Related Problem"],
        ["PPR", "Pin Point Related Defect"],
        ["PSD", "PACESETTER Problem"],
        ["PTD", "Positive Train Control Defect"],
        ["QAQ", "LOCOMOTIVE OPERATIONS REQUEST"],
        ["QQQ", "MECH QUALITY INSPECTION INFORMATION"],
        ["RAD", "Radio Related Defect"],
        ["RCD", "Remote Control Defect"],
        ["RDD", "HTD Related Defect"],
        ["REX", "Faults Associated with the REX"],
        ["RHP", "Engine Running Hot"],
        ["RMD", "GE Remote Monitoring and Diagnostics"],
        ["RSD", "SD Card Replaced"],
        ["RSM", "Failure/Faults of the Router Switch Module (RSM)"],
        ["RTD", "Retired Locomotive"],
        ["RTS", "Route To Shop"],
        ["SAB", "Safety Appliance and Brake Only Federal Inspection"],
        ["SCT", "System Configuration Timeout"],
        ["SID", "Speed Indicator/Recorder"],
        ["SRP", "Sand Inoperative/Out Of Sand/Wet Sand"],
        ["SSS", "Shopping Created Incident"],
        ["SWL", "Software Loaded"],
        ["SWM", "Mismatched Component Software"],
        ["SWO", "Software Versions are out of compliance"],
        ["TB1", "TBOGI Defect Level 1 Imperative Response"],
        ["TB2", "TBOGI Defect Level 2 Inspection Response"],
        ["TB3", "TBOGI Defect Level 3 Limited Liability"],
        ["TB4", "TBOGI Defect Level 4 Minor Liability"],
        ["TB5", "TBOGI Defect Level 5 Minor Liability"],
        ["TB6", "TBOGI Defect Level 6 Minor Liability"],
        ["TBL", "Trimble Location Defect"],
        ["TBP", "Trimble Defect"],
        ["TCD", "Train Control Defect"],
        ["TCI", "Train Control Inspection"],
        ["TEN", "Faults Associated with the Slot-10 module"],
        ["TFS", "TMC in a Failed State"],
        ["TMP", "Traction Motor Cut Out Problem"],
        ["TOD", "Toilet Defect"],
        ["TOP", "Trip Optimizer Problem"],
        ["TP1", "Truck Performance Level 1 Imperative Response"],
        ["TP2", "Truck Performance Level 2 Inspection Response"],
        ["TP3", "Truck Performance Level 3 Limited Liability"],
        ["TP4", "Truck Performance Level 4 Minor Liability"],
        ["TRD", "Transition, Not Making"],
        ["TRF", "Transfer Between Shops"],
        ["TRP", "Throttle Response Problem"],
        ["TSI", "Faults involving the Tamper Switch intrusion bar."],
        ["UCO", "Unauthorized Hard Cut-out"],
        ["UTB", "U Tube Bearing Grease Sample Failure"],
        ["VTB", "Vehicle Track - Box"],
        ["VTI", "Vehicle/Track Interaction"],
        ["VTL", "Vehicle Track - Locomotive"],
        ["VTS", "Vehicle Track - Sensor"],
        ["WCP", "Water Cooler Problem"],
        ["WD1", "Wheel Defect, Level 1 Impending Damage To Track/Equipment"],
        ["WD2", "Wheel Defect, Level 2 Restricted Movement To Shop"],
        ["WD3", "Wheel Defect Level 3 Limited Liability"],
        ["WD4", "Wheel Defect Level 4 Minor Liability"],
        ["WD5", "Wheel Defect Level 5 Minor Liability"],
        ["WD6", "Wheel Defect Level 6 Minor Liability"],
        ["WP1", "Wheel Profile Defect 1"],
        ["WP2", "Wheel Profile Defect 2"],
        ["WP3", "Wheel Profile Defect 3"],
        ["WP4", "Wheel Profile Defect 4"],
        ["WP5", "Wheel Profile Defect 5"],
        ["WP6", "Wheel Profile Defect 6"],
        ["WSD", "Wheel Slip Problems"],
        ["WSP", "Wheel Slip in Motoring/Power"],
        ["WTF", "Fault related to Wheel Tachometer or Speed"],
        ["WWP", "Windshield Wiper Problem"]
    ]);
