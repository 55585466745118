export const appConfigMapping = {
  security: {
    securityDomains: (<any>window).appConfig.security.securityDomains,
    clientId: (<any>window).appConfig.security.clientId,
    autoConfigUrl: (<any>window).appConfig.security.autoConfigUrl
  },
  api: {
    // You need to update this with the list of your APIs (needs to match app.config.js)
    trainsApi: (<any>window).appConfig.api.trainsApi,
    directionsApi: (<any>window).appConfig.api.directionsApi,
    imageApi: (<any>window).appConfig.api.imageApi
  },
  googleAnalyticsTrackingId: (<any>window).appConfig.googleAnalyticsTrackingId,
  googleAnalyticsURL: (<any>window).appConfig.googleAnalyticsURL
};
