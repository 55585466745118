import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'departure-test-pg5-final',
  templateUrl: './departure-test-pg5-final.component.html',
  styleUrls: ['./departure-test-pg5-final.component.scss']
})
export class DepartureTestPg5FinalComponent implements OnInit {
  successMessage = "Cab Signal and Train Control Departure Test has been submitted";
  
  constructor() { }

  ngOnInit(): void {
  }

}
