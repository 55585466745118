import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LcdiCategory } from '../models/lcdi-category.model';
import { LcdiQuestion } from '../models/lcdi-question.model';
import { SubmitInspectionAnswer } from '../models/submit-inspection-answer.model';
import { FlexService } from 'csx-flex-ng-oidc';

@Injectable({
  providedIn: 'root'
})
export class LcdiCategoriesService {
  private _lcdiCategories: BehaviorSubject<LcdiCategory[]> =
    new BehaviorSubject<LcdiCategory[]>([]);
  // don't want to explose behavior subject directly
  readonly lcdiCategories$ = this._lcdiCategories.asObservable();

  private lcdiCategories: LcdiCategory[] = [];

  constructor(private Flex: FlexService) {};

  // builds an LcdiCategory[] from service data and creates behavior subject from it
  buildLcdiQuestionsAndCategories(testQuestionsData: any[]): void {
    // array to return when built
    let categoryBuilder: LcdiCategory[] = [];
    // loop through service data to build
    for (let data of testQuestionsData) {
      // create the question from the data
      let newQuestion: LcdiQuestion = {
        questionId: data.CLIP_QUESTIONSID,
        questionText: data.QUESTION,
        answer: undefined,
        answerComment: undefined,
        catId: data.CATEGORYID
      };
      // figure out category stuff
      let categoryId = data.CSXGROUP;
      // category already created?
      let foundIndex = categoryBuilder.findIndex(
        obj => obj.csxCategoryId === categoryId
      );
      if (foundIndex === -1) {
        // no: create new question array and category, then push
        let newQuestions: LcdiQuestion[] = [newQuestion];
        let newCategory: LcdiCategory = {
          csxCategoryDescription: data.CSXDESCRIPTION,
          csxCategoryId: data.CSXGROUP,
          lcdiQuestions: newQuestions
        };
        categoryBuilder.push(newCategory);
      } else {
        // yes: push the new question into the found index for it's category
        categoryBuilder[foundIndex].lcdiQuestions.push(newQuestion);
      }
    }
    this.lcdiCategories = categoryBuilder;
    this._lcdiCategories.next(this.lcdiCategories);
  }

  updateAnswer(category: LcdiCategory): void {
    let foundIndex = this.lcdiCategories.findIndex(
      obj => obj.csxCategoryId === category.csxCategoryId
    );
    this.lcdiCategories[foundIndex] = category;
    this._lcdiCategories.next(this.lcdiCategories);
  }

  // convert answers in categories array to format needed for procedure to be invoked with inspection and user Id's
  convertAnswersForProcedureInvocation(
    categories: LcdiCategory[],
    inspectionId: number,
    userId: string
  ): SubmitInspectionAnswer[] {
    let jsonParameters: SubmitInspectionAnswer[] = [];
    for (let category of categories) {
      for (let answer of category.lcdiQuestions) {
        let inspectionAnswer: SubmitInspectionAnswer = {
          id: answer.questionId,
          category: answer.catId,
          answer: answer.answer,
          comment: answer.answerComment ? answer.answerComment : '',
          created: new Date(),
          createdby: userId,
          inspectionid: inspectionId
        };
        jsonParameters.push(inspectionAnswer);
      }
    }

    return jsonParameters;
  }

  getLcdiQuestions(formType: string) {
    let result = [];
    let questionsInvocation = {
      adapter: 'sql_clip',
      procedure: 'getLCDIQuestionsSql',
      parameters: [formType]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      }
    };

    return this.Flex.invokeProcedure(questionsInvocation, options).then(
      function (response) {
        console.log('getLcdiQuestions: ', response);
        return response;
      }
    );
  }
}
