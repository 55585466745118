import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, AuthStates, FlexService, User } from 'csx-flex-ng-oidc';
import { CSXHeaderModel } from 'csx-ng';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../services/google-analytics/google-analytics.service';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-template',
  templateUrl: './app-template.component.html',
  styleUrls: ['./app-template.component.scss']
})
export class AppTemplateComponent implements OnInit, OnDestroy {
  items: MenuItem[];
  headerModel: CSXHeaderModel;
  authSubscription: Subscription;
  loading: boolean;

  constructor(
    appUser: User,
    private Flex: FlexService,
    private authService: AuthService,
    public router: Router,
    private analytics: AnalyticsService,
    private cd: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {
    // authService.onUserUpdated.subscribe((user: User) => {
    //   this.headerModel.userName = user.displayName;
    //   this.headerModel.userRacf = user.racf;
    //   this.cd.detectChanges();
    // });

    this.headerModel = {
      appShortName: 'CLIP',
      appLongName: 'CSX Locomotive Inspection Portal',
      userName: 'Unknown',
      userRacf: '1234'
    };
    this.items = [
      {
        label: 'Enter Details'
      }
    ];
  }

  ngOnInit() {
    this.authSubscription = this.authService
      .isAuthorized()
      .subscribe(authStatus => {
        if (authStatus === AuthStates.AUTHORIZED) {
          if (this.Flex.user) {
          }
        }
      });
  }

  ngOnDestroy() {
    console.log('ngOnDestroy: Unsubscribed to auth status ');
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
