import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppLocInspService } from '../../services/app-loc-insp/app-loc-insp.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AuthService, AuthStates, FlexService } from 'csx-flex-ng-oidc';


@Component({
  selector: 'locomotive-details',
  templateUrl: './locomotive-details.component.html',
  styleUrls: ['./locomotive-details.component.scss']
})
export class LocomotiveDetailsComponent implements OnInit {
  locoInit: string;
  locoNum: string;
  locomotiveForm: FormGroup;
  locomotiveAlerts: any;
  locomotiveIncidents: any;
  boolShowSpin: boolean = true;

  constructor(private Flex : FlexService,
    private authService: AuthService,
    private router: Router,
    public appLocInspService: AppLocInspService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.locoInit = "";
    this.locoNum = "";

    this.locomotiveForm = this.formBuilder.group({
      locoInitial: ['', [Validators.required, Validators.minLength(2),  Validators.maxLength(4), Validators.pattern('^[a-zA-Z]*$')]],
      locoNumber: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(6), Validators.pattern('[0-9]+'), this.validateLocoNum]]
   });
    this.authService
      .isAuthorized()
      .subscribe(authStatus => {
        if (authStatus === AuthStates.AUTHORIZED) {
          if (this.authService.getAuthenticatedUser() !== undefined) {
            this.boolShowSpin = false;
          }
        }
      });

  }

  getLocomotiveDetails()
  {
    this.locoInit = this.locomotiveForm.controls.locoInitial.value.toUpperCase();

    if (this.locoInit == "CSX"){
        this.locoInit = "CSXT";
    }

    this.locoNum = this.locomotiveForm.controls.locoNumber.value;

    if (this.locoNum.length < 6){
      var numPad = 6 - this.locomotiveForm.controls.locoNumber.value.length;
      for (let i = 0; i < numPad; i++) {
				this.locoNum = '0' + this.locoNum;
			}
    }
    console.info("locoid: " + this.locoInit, this.locoNum);
    this.appLocInspService.setLocoInformation(this.locoInit, this.locoNum);
    this.router.navigate(['/loco-insp']);
  }

  validateLocoNum(c: FormControl) {
    if (c.value.length == 6 && c.value.substring(0,2) != '00') {
        return { validateLocoNum: {valid: false}};
    } else {
        return null;
    }
  }
}
