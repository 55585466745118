export class ReportIncident {
  locoID: string;
  incidentDate: string;
  userID: string;
  problemCode: string;
  problemDetails: string;
  resultValue: string;


  constructor() {
    this.locoID = '';
    this.userID = '';
    this.incidentDate = '';
    this.problemCode = '';
    this.problemDetails = '';
    this.resultValue = '';
  }
}