import { Component, OnInit, ViewChild } from '@angular/core';
import { SubmitInspectionAnswerDepartureTest } from '../calendar-day-inspection/models/submit-inspection-answer.model';
import { DepartureTestPg1SetupComponent } from './departure-test-pg1-setup/departure-test-pg1-setup.component';
import { DepartureTestPg2TestComponent } from './departure-test-pg2-test/departure-test-pg2-test.component';
import { DepartureTestPg3RecordComponent } from './departure-test-pg3-record/departure-test-pg3-record.component';
import { DepartureTestPg4ResultComponent } from './departure-test-pg4-result/departure-test-pg4-result.component';
import { Subscription } from 'rxjs';
import { AuthService, AuthStates, FlexService } from 'csx-flex-ng-oidc';
import { SubmitInspection } from '../calendar-day-inspection/models/submit-inspection.model';
import { AppLocInspService } from '../../../../src/app/services/app-loc-insp/app-loc-insp.service';
import { LocomotiveDetail } from '../app-loc-insp/model/app-loc-insp-Locomotive';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'departure-test',
  templateUrl: './departure-test.component.html',
  styleUrls: ['./departure-test.component.scss']
})
export class DepartureTestComponent implements OnInit {
  @ViewChild(DepartureTestPg1SetupComponent) pg1!: DepartureTestPg1SetupComponent;
  @ViewChild(DepartureTestPg2TestComponent) pg2!: DepartureTestPg2TestComponent;
  @ViewChild(DepartureTestPg3RecordComponent) pg3!: DepartureTestPg3RecordComponent;
  @ViewChild(DepartureTestPg4ResultComponent) pg4!: DepartureTestPg4ResultComponent;

  inspectionAnswers: SubmitInspectionAnswerDepartureTest[] = [];
  inspectionId = -1;
  selected = 1;
  lastIdUsed = 0;
  userId: string;
  imageUrls: any[];
  imagesSet: any[]=[];

  nextButtonText = "Next";
  nextButtonTextAlt = "Submit";
  backButtonText = "Back";
  backButtonTextAlt = "Cancel";
  showAlternateNext = false;
  showAlternateBack = false;

  // hard coded
  number = 22345600;
  division = "FL";
  location = "Jacksonville";
  date: Date = new Date("2022-01-10T03:15:11");

  authSubscription: Subscription;
  locomotiveDetail: LocomotiveDetail;
 

  options = {
    onSuccess: function (response) {
      return response;
    },
    onFailure: function (response) {
      return response;
    }
  };

  constructor(
    private Flex: FlexService,
    private authService: AuthService,
    private appLocInspService: AppLocInspService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.userId = null;
    this.initializeAndGetData();

    this.appLocInspService.locoInfo$.subscribe(locomotiveDetail => {
      this.locomotiveDetail = locomotiveDetail
    });       
    this.appLocInspService.locoLocation$.subscribe(val=>{
      this.location=val;
    });
  }

  ngAfterViewInit() {
  }

  initializeAndGetData(): void {
    this.authSubscription = this.authService
      .isAuthorized()
      .subscribe(authStatus => {
        if (authStatus === AuthStates.AUTHORIZED) {
          if (this.Flex.user) {
            this.userId = this.Flex.user.id;
          }
        }
      });
  }  

  nextAction() {
    console.log("next action called");

    if (this.selected == 3) {
      this.showAlternateNext = true;
    }

    this.selected += 1;
  }

  submitAction() {
    console.log("submit action called");
    this.loaderService.setLoading(true);
    this.inspectionAnswers = this.pg1.inspectionAnswers.concat(this.pg2.inspectionAnswers, this.pg3.inspectionAnswers, this.pg4.inspectionAnswers)

    this.submitInspection();    
  }

  backAction() {
    console.log("back action called");
    this.showAlternateNext = false;

    if (this.selected == 1) {
      // handle cancellation
    } else {
      this.selected -= 1;
    }
  }

  onImageUploadCab(imageUrls: any[]):void{
    this.imagesSet.push(imageUrls);
    console.log('&&&&&&&&&&'+imageUrls[0]);
      }


  submitInspection(): void {
    let parameters = [
      [
        null,
        this.userId,
        (new Date()).toLocaleString(),
        this.locomotiveDetail.locoInit + this.locomotiveDetail.locoNumber,
        null,
        this.location,
        (new Date()).toLocaleString(),
        3
      ]
    ];

    let submitInspectionSqlInvocation = {
      adapter: 'sql_clip',
      procedure: 'submitInspectionSql',
      parameters: parameters
    };

    this.Flex.invokeProcedure(submitInspectionSqlInvocation, this.options).then(
      response => {
        if (
          response.invocationResult &&
          response.invocationResult.result &&
          response.status === 200
        ) {
          this.inspectionId = response.invocationResult.result[0].CLIP_INSPECTIONSID;
          console.log(this.inspectionId);
          
          this.filterInInspections();
          this.submitImagesProcedure(this.inspectionId);
          this.callSubmitInspectionAnswersSqlProcedure();
        } else {
          return -1;
        }
      }
    );
  }

  // if no selection (no answer or comment, don't progagate )
  filterInInspections() {
    let finalInspectionAnswers: SubmitInspectionAnswerDepartureTest[] = [];

    for (let x of this.inspectionAnswers) {
      if (!(x.answer == "" && x.comment == "")) {
        x.category = 0;
        x.created = new Date();
        x.createdby = this.userId;
        x.inspectionid = this.inspectionId;

        console.log(x);
        finalInspectionAnswers.push(x);
      }
    }

    this.inspectionAnswers = finalInspectionAnswers;
    console.log(this.inspectionAnswers);
  }
  

  callSubmitInspectionAnswersSqlProcedure(): void {
    let submitInspectionAnswersSqlInvocation = {
      adapter: 'sql_clip',
      procedure: 'submitInspectionAnswersSql',
      parameters: [JSON.stringify(this.inspectionAnswers)]
    };

    this.Flex.invokeProcedure(submitInspectionAnswersSqlInvocation, this.options).then(response => {
      if (
        response.invocationResult &&
        response.invocationResult.result &&
        response.status === 200
      ) {
        this.selected += 1;
        this.loaderService.setLoading(false);
      } else {
        // FIXME implement failure path
        console.log('error in callSubmitInspectionAnswersSqlProcedure()', response);
      }
    });
  }  

  submitImagesProcedure(this: this,inspectionId: any){

    this.imagesSet.forEach(img=>this.submitImg(img,inspectionId));
        
      }
    
      submitImg(img: any[],inspectionId: any){
        this.appLocInspService.createImageEntryInMaximo(inspectionId,img[0],img[1],
        this.userId, img[2], 'CLIP_INSPECTIONS').then(
         result => {
           console.log(result);
         }
       ).catch(err => console.error('createImageForMaximo: ', err));
      }



}
