import { Component, Input, OnInit } from '@angular/core';
import { SubmitInspectionAnswerDepartureTest } from '../../calendar-day-inspection/models/submit-inspection-answer.model';

@Component({
  selector: 'departure-test-pg4-result',
  templateUrl: './departure-test-pg4-result.component.html',
  styleUrls: ['./departure-test-pg4-result.component.scss']
})
export class DepartureTestPg4ResultComponent implements OnInit {
  options: any[];
  inspectionAnswers: SubmitInspectionAnswerDepartureTest[] = new Array(2);

  @Input() userId: string;
  testedBy;
  repairedBy;

  madeChoice = false;
  colorChosen = "none";

  greenMessage = "No Defects Found: OK For Lead Operation";
  yellowMessage = "Defects Found, Repairs Were Made: OK For Lead Operation";
  redMessage = "Defects Found, No Repairs Made: Non-Leader Use Only";
  selectedMessage = "";
  
  constructor() { }

  ngOnInit(): void {
    this.assignIdToAnswers();
    
    this.options = [
      { label: 'No Defects Found: OK For Lead Operation', value: 'OK', icon: 'csx-common_check' },
      { label: 'Defects Found, Repairs Were Made: OK For Lead Operation', value: 'DOK', icon: 'csx-common_remove_circle' },
      { label: 'Defects Found, No Repairs Were Made: Non Leader Use Only', value: 'NOK', icon: 'csx-common_remove_circle' }
    ];
  }

  handleClick(color: string){
    switch (color) {
      case "green":
        console.log("we out here; green");
        this.selectedMessage = this.greenMessage;
        this.colorChosen = "green";
        break;
      case "yellow":
        console.log("we out here; yellow");
        this.selectedMessage = this.yellowMessage;
        this.colorChosen = "yellow";
        break;
      case "red":
        console.log("we out here; red");
        this.selectedMessage = this.redMessage;
        this.colorChosen = "red";
        break;
      case "reset":
        console.log("we out here; reset");
        this.colorChosen = "none";
        this.selectedMessage = "";
    }
  }

  assignIdToAnswers() {
    this.inspectionAnswers[0] = {}; this.inspectionAnswers[0].id = 131; this.inspectionAnswers[0].category = 0;
    this.inspectionAnswers[0].answer = ""; this.inspectionAnswers[0].comment = this.userId;

    this.inspectionAnswers[1] = {}; this.inspectionAnswers[1].id = 132; this.inspectionAnswers[1].category = 0;
    this.inspectionAnswers[1].answer = ""; this.inspectionAnswers[1].comment = this.userId;
  }  

}
