import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LocomotiveIncidentMap } from '../../app-loc-incident.map';
import { AppReportIncidentComponent } from '../app-report-incident.component';

class ProblemCodeDesc {
  code: string;
  desc: string;
  org: string;
};

@Component({
  selector: 'app-problem-popup',
  templateUrl: './app-problem-popup.component.html',
  styleUrls: ['./app-problem-popup.component.scss']
})
export class AppProblemPopupComponent implements OnInit {
  private _reportIncident: AppReportIncidentComponent;
  selectedPCode : ProblemCodeDesc;

  @Input() set reportIncident(value: AppReportIncidentComponent) {
    this._reportIncident = value;
  }

  get reportIncident(): AppReportIncidentComponent {
    return this._reportIncident;
  }

  cols : any[];
  problemCodeDesc  : ProblemCodeDesc[];

  constructor() {
    this.problemCodeDesc = [];
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'code', header: 'Problem Code' },
      {field: 'desc', header: 'Description' },
      { field: 'org', header: 'Organization' }
  ];


  LocomotiveIncidentMap.forEach( (k,v) => {
      var tmp = new ProblemCodeDesc();
      tmp.code = v;
      tmp.desc = k;
      tmp.org = "CSXMECH";
      this.problemCodeDesc.push(tmp);
  })
  }

  onRowSelect($event)
  {
    console.log($event.data);
    this.closeDialog();
  }

  closeDialog()
  {
    this._reportIncident.showProblemCode = false;
    this._reportIncident.parentListener(this.selectedPCode);
  }
}