import { Component, OnInit } from '@angular/core';
import { CSXHeaderModel } from 'csx-ng';
import { FlexService } from 'csx-flex-ng-oidc';
import { LoaderService } from './services/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'app';
  userName: string = "";
  userRacf: string = "";
  headerModel: CSXHeaderModel;
  loading: boolean;

  ngOnInit() {
    this.loaderService.loading.subscribe((status) => {
      if (status) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    });
  }   

  constructor(
    Flex: FlexService,
    private loaderService: LoaderService
    ) {
    
    setTimeout (() => {
      if(Flex.user !== undefined)
      {
        this.userName = Flex.user.firstName +
        " " + Flex.user.lastName;
        this.userRacf = Flex.user.id;
        this.headerModel.userName = this.userName;
        this.headerModel.userRacf = this.userRacf;
      }
   }, 2000);

    this.headerModel = {
      appShortName: 'CSX',
      appLongName: 'CLIP -  CSX Locomotive Inspection Portal',
      /*   userName: 'Unknown',
         userRacf: '1234' */
      userName: this.userName,
      userRacf: this.userRacf
    };

  }
}
