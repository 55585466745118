import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocomotiveDetail } from '../../components/app-loc-insp/model/app-loc-insp-Locomotive';
import { FlexService } from 'csx-flex-ng-oidc';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppLocInspService {

  constructor(private Flex: FlexService,
    private http: HttpClient) { };

  locomotiveDetail: LocomotiveDetail = { locoInit: "", locoNumber: "" };  

  private _locoInformation: BehaviorSubject<LocomotiveDetail> =
    new BehaviorSubject<LocomotiveDetail>(null); // Can change this later to an array if required

    private _locoLocation: BehaviorSubject<string> =
    new BehaviorSubject<string>(null); // Can change this later to an array if required

    private _locoDivision: BehaviorSubject<string> =
    new BehaviorSubject<string>(null); // Can change this later to an array if required


  private _submitSuccess: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private _submitWRSuccess: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  locoInfo$ = this._locoInformation.asObservable();
  submitSuccess$ = this._submitSuccess.asObservable();
  submitWRSuccess$ = this._submitWRSuccess.asObservable();
  locoLocation$ = this._locoLocation.asObservable();
  locoDivision$ = this._locoDivision.asObservable();

  setLocoInformation(locoInit, locoNum) {
    this.locomotiveDetail.locoInit = locoInit;
    this.locomotiveDetail.locoNumber = locoNum;
    this._locoInformation.next(this.locomotiveDetail);
  }

  setLocation(location: string){
    this._locoLocation.next(location);
  }

  setDivision(division: string){
    this._locoDivision.next(division);
  }

  setBoolForSubmitCompletion(success: boolean) {
    this._submitSuccess.next(success);
  }

  setBoolForSubmitWRCompletion(success: boolean) {
    this._submitWRSuccess.next(success);
  }

  getLocationAssetDetails(locoId: string) {
    let locationAssetDetails = {
      adapter: 'sql_clip',
      procedure: 'getAssetNumSql',
      parameters: [locoId]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      },
      timeout: 120000
    };

    return this.Flex.invokeProcedure(locationAssetDetails, options).then(
      function (response) {
        console.log('getLocationAssetDetails: ', response);
        return response;
      }
    );
  }

  uploadFileForReportIncident(formData: FormData, fileName: string) {
    console.log("uploadFileForReportIncident : " + fileName);
    let apigeeToken = JSON.parse(localStorage.getItem('tokenResponse')).accessToken;
    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + apigeeToken
    });

    //let endPoint = 'https://clip-api-mechanical-dev.apps.ocpjaxt001.csx.com/clip-api/api/v1/object/' + fileName+'/upload';
    //let endPoint = 'https://apidev.csx.com/clip/v1/object/'+fileName+'/upload' ;
    let endPoint = environment.appConfig.api.imageApi+'object/'+fileName+'/upload' ;

   /* return this.http.post<any>(endPoint, formData, { headers: headers }).subscribe(
      (res) => {
        console.log(res);
      },
        (err) => console.log(err)
    ); */
    return this.http.post<any>(endPoint, formData, { headers: headers });
  }

  createImageEntryInMaximo(inspectionId: string, questionId: string, presignedURL: string,
    userId:string, fileName: string, formType:string)
  {
    var rescCtype: any = [];
    let imgFileDetails = {
      adapter: 'sql_clip',
      procedure: 'createImageSql',
      parameters: [[inspectionId, questionId, presignedURL, userId, fileName, formType,rescCtype]]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      },
      timeout: 120000
    };

    return this.Flex.invokeProcedure(imgFileDetails, options).then(
      function (response) {
        console.log('createImageEntryInMaximo: ', response);
        return response;
      }
    ).catch((err) => console.log(err));
  }

}
