import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LcdiCategory } from '../models/lcdi-category.model';

@Component({
  selector: 'question-summary',
  templateUrl: './question-summary.component.html',
  styleUrls: ['./question-summary.component.scss']
})
export class QuestionSummaryComponent implements OnInit {
  @Input() activeContainer: string;
  @Input() category: LcdiCategory;
  @Output() onCategorySelected: EventEmitter<any> = new EventEmitter();

  answeredQuestions: number;
  constructor() {}

  ngOnInit(): void {}

  selectCategory(category: LcdiCategory): void {
    this.activeContainer = category.csxCategoryDescription;
    this.onCategorySelected.emit(category);
  }

  getAnsweredCount(category: LcdiCategory): number {
    return category.lcdiQuestions.reduce((counter, question) => {
      if (question.answer) {
        if (question.answer !== 'N' || question.answerComment) {
          counter += 1;
        }
      }
      return counter;
    }, 0);
  }

  getAnswerProgress(category: LcdiCategory): number {
    let answeredQuestions = this.getAnsweredCount(category);
    let progress = 0;
    if (answeredQuestions !== 0) {
      // determinate progress bar requires # 0-100
      progress = (answeredQuestions / category.lcdiQuestions.length) * 100;
    }
    return progress;
  }

  // mapping function to get the icon class based on categoryId
  getCategoryIcon(csxCategoryId: string): string {
    let iconClass: string;
    switch (csxCategoryId) {
      case '1':
        iconClass = 'csx-train_caravan';
        break;
      case '2':
        iconClass = 'csx-common_lightbulb';
        break;
      case '3':
        iconClass = 'csx-speed_high_acceleration';
        break;
      case '4':
        iconClass = 'csx-repair_safety_works';
        break;
      case '5':
        iconClass = 'csx-repair_brake_shoes';
        break;
      case '6':
        iconClass = 'csx-repair_wrench';
        break;
      case '7':
        iconClass = 'csx-device_wiring';
        break;
      default:
        iconClass = 'csx-common_help';
    }
    return iconClass;
  }
}
