import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, AuthStates, FlexService } from 'csx-flex-ng-oidc';
import { take } from 'rxjs/internal/operators/take';
import { AsyncSubject } from 'rxjs';

@Injectable()
export class RouteGuardService implements CanActivate {

  constructor(private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let isAuthSubject: AsyncSubject<boolean> = new AsyncSubject<boolean>();
    console.log("RouteGuardService : Checking Route access");
    /*
    console.log("Route is : " + route.outlet + " " + route.component);
    console.log("Auth user is : " + this.authService.getAuthenticatedUser());
    console.log("Router state is : " + state.url);
    */

    this.authService.isAuthorized().pipe(take(1)).subscribe((res) => {
      if (res === AuthStates.AUTHORIZED) {
        isAuthSubject.next(true);
      } else {
        isAuthSubject.next(false);
      }

      isAuthSubject.complete();
    })

    return isAuthSubject.asObservable();
  }
}
