import { Component, OnInit ,EventEmitter, Output } from '@angular/core';
import { SubmitInspectionAnswerDepartureTest } from '../../calendar-day-inspection/models/submit-inspection-answer.model';
import { MenuItem } from 'primeng/api';
import { AppLocInspService } from '../../../services/app-loc-insp/app-loc-insp.service';
import { FlexService } from 'csx-flex-ng-oidc';
import { LoaderService } from '../../../services/loader/loader.service';

declare const navigator: any;

interface FileInfo {
  file: File;
  userId: string;
}

@Component({
  selector: 'departure-test-pg3-record',
  templateUrl: './departure-test-pg3-record.component.html',
  styleUrls: ['./departure-test-pg3-record.component.scss']
})
export class DepartureTestPg3RecordComponent implements OnInit {
  @Output() onImageUploadCab: EventEmitter<any> = new EventEmitter();
  options: any[];
  inspectionAnswers: SubmitInspectionAnswerDepartureTest[] = new Array(5);
  switches: string[] = new Array(5);
  private items: MenuItem[];
  fileUploadQuestionId: string;
  fileInfo: FileInfo;
  imageUrls: any[]=[];
  
  constructor(private Flex: FlexService,private appLocInspService: AppLocInspService,private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.assignIdToAnswers();

    this.options = [
      { label: 'Yes', value: 'Y', icon: 'csx-common_check' },
      { label: 'No', value: 'N', icon: 'csx-common_remove_circle' },
    ];   
    
    this.items = [
      {label: 'From Camera', icon: 'pi pi-camera', command: () => {
          this.cameraClick();
      }},
      {label: 'Photo Library', icon: 'pi pi-images', command: (x) => {
        this.clickFileUpload();
      }}
  ];

  this.fileInfo = {file: null, userId: this.Flex.user.id};
  }

  addPhoto(e) {
    console.log("called addPhoto()"+e.currentTarget.id);
  }

  assignIdToAnswers() {
    this.inspectionAnswers[0] = {}; this.inspectionAnswers[0].id = 126; this.inspectionAnswers[0].category = 0;
    this.inspectionAnswers[0].answer = "";  this.inspectionAnswers[0].comment = "";
    
    this.inspectionAnswers[1] = {}; this.inspectionAnswers[1].id = 127; this.inspectionAnswers[1].category = 0;
    this.inspectionAnswers[1].answer = "";  this.inspectionAnswers[1].comment = "";
    
    this.inspectionAnswers[2] = {}; this.inspectionAnswers[2].id = 128; this.inspectionAnswers[2].category = 0;
    this.inspectionAnswers[2].answer = "";  this.inspectionAnswers[2].comment = "";
    
    this.inspectionAnswers[3] = {}; this.inspectionAnswers[3].id = 129; this.inspectionAnswers[3].category = 0;
    this.inspectionAnswers[3].answer = "";  this.inspectionAnswers[3].comment = "";
    
    this.inspectionAnswers[4] = {}; this.inspectionAnswers[4].id = 130; this.inspectionAnswers[4].category = 0;
    this.inspectionAnswers[4].answer = "";  this.inspectionAnswers[4].comment = "";
  }  

  clickImageDropdown(questionId) {
    this.fileUploadQuestionId = questionId;
  }

  clickFileUpload() {
    document.getElementById(this.fileUploadQuestionId).click();
    this.loaderService.setLoading(true);
  }

  getFile(e): void{
    console.log('Chosen file : ' + e.currentTarget.files[0].name);
    // Send to AWS S3
    this.fileInfo.file = e.currentTarget.files[0];
    this.sendFileToAWS(e.currentTarget.files[0].name,e.currentTarget.id);
  }


  sendFileToAWS(fileName: string,question: string) {
    const formData: FormData = new FormData();
    var rescCtype: any;
    var formType: string = 'CLIP_INSPECTIONS';
    formData.append("file", this.fileInfo.file, fileName);
    this.appLocInspService.uploadFileForReportIncident(formData, fileName).subscribe(response => {
      console.log(response);
      
      var images=[question,response.presignedurl,fileName];
      this.imageUrls.push(images);
      this.onImageUploadCab.emit(images);
      console.log('images'+this.imageUrls[2]);
      this.loaderService.setLoading(false);
      
      //presignedurl
      // below hard coded incident number and question id, has to be handled //      
    }, (err) => {console.log(err);
      this.loaderService.setLoading(false);
    });
  }
  cameraClick() {
    var instance = this;
    var photo;
    return new Promise(function (resolve, reject) {
      try {
        if (navigator.camera == undefined) {
          alert("navigator.camera == undefined");
        } else {
          navigator.camera.getPicture(
            (imageUri) => {
              photo = imageUri;
             // instance.fileInfo.file = photo;
              let tmpFileName = instance.appLocInspService.locomotiveDetail.locoInit+instance.appLocInspService.locomotiveDetail.locoNumber + new Date().toString();
              this.sendFileToAWS(tmpFileName,this.fileUploadQuestionId);
            },
            (error) => {
              alert("Unable to obtain picture: " + error);
            },
            {
              quality: 50,
              destinationType: navigator.camera.DestinationType.DATA_URL
            }
          )
        }
      } catch (e) {
        alert(e);
      }
    });
  }

}
