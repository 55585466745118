import { Component, OnInit } from '@angular/core';
import { FlexService } from 'csx-flex-ng-oidc';
import { formatDate } from '@angular/common';
import { LocomotiveDetail } from '../app-loc-insp/model/app-loc-insp-Locomotive';
import { AppLocInspService } from '../../services/app-loc-insp/app-loc-insp.service';
import { InspectionHistoryLocationModel } from '../../models/inspection-history-location-model';
import { InspectionHistoryModel } from '../../models/inspection-history-model';
import { InspectionHistoryQuestionModel } from '../../models/inspection-history-question-model';
import { InspectionHistoryUserModel } from '../../models/inspection-history-user-model';

@Component({
  selector: 'inspection-history',
  templateUrl: './inspection-history.component.html',
  styleUrls: ['./inspection-history.component.scss']
})
export class InspectionHistoryComponent implements OnInit {
  dateRange: Date[];

  locationResults: InspectionHistoryLocationModel[];
  userResults: InspectionHistoryUserModel[];
  questions: InspectionHistoryQuestionModel[];
  location = "";
  user = "";

  initialList: InspectionHistoryModel[];
  locomotiveDetail: LocomotiveDetail;
  locomotiveString = "";

  lastRetrievedInspectionsId = "";
  displayModal = false;

  options = {
    onSuccess: function (response) {
      return response;
    }, onFailure: function (response) {
      return response;
    }, timeout: 120000
  };

  constructor(
    private Flex: FlexService,
    private appLocInspService: AppLocInspService
    ) { }

  ngOnInit(): void {
    this.appLocInspService.locoInfo$.subscribe(locomotiveDetail => {
      this.locomotiveDetail = locomotiveDetail
      this.locomotiveString = this.getLocomotiveString();
    });    
  }

  getHistoryLocationAndUser(gettingLocation: boolean) {
    let locoAlertsData;

    if (gettingLocation) {
      locoAlertsData = {
        adapter: "sql_clip",
        procedure: 'getHistoryLocationsSql',
        parameters: [this.location]
      };
    } else {
      let userUpperCase = this.user.toUpperCase(); // backend expects username in uppercase

      locoAlertsData = {
        adapter: "sql_clip",
        procedure: 'getUsersSql',
        parameters: [userUpperCase]
      };
    }

    this.Flex.invokeProcedure(locoAlertsData, this.options).then((response) => {
      if (response.invocationResult.result && response.status === 200) {
        if (gettingLocation) {
          this.locationResults = response.invocationResult.result;
        } else {
          this.userResults = response.invocationResult.result;
        }
      }
    });
  }
  
  getInspectionHistory(locomotive: string, location: any, user: any, fromdate: string, todate: string) {
    let param_array = [locomotive, location.LOCATION, user.PERSONID, fromdate, todate, ""];

    let inspectionHistoryData = {
      adapter: "sql_clip",
      procedure: 'getInspectionHistorySql',
      parameters: [param_array]
    };

    this.Flex.invokeProcedure(inspectionHistoryData, this.options).then((response) => {
      if (response.invocationResult.result && response.status === 200) {
        console.log(response.invocationResult.result);
        this.initialList = response.invocationResult.result;
      }
    });
  }

  updateList() {
    const format = 'yyyy-MM-dd';  
    let fromdate = "";
    let todate = "";
    let user ="";
    let location="";

    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      fromdate = formatDate(this.dateRange[0], format, "en-US");
      todate = formatDate(this.dateRange[1], format, "en-US");
    }
    if(this.user){
      user=this.user;
    }

    if(this.location){
      location=this.location;
    }


    this.getInspectionHistory('', location, user, fromdate, todate);
  }

  getRowInfo(inspectionsId: string) {
    let inspectionHistoryData = {
      adapter: "sql_clip",
      procedure: 'getInspectionHistorySql',
      parameters: [["", "", "", "", "", inspectionsId]]
    };

    this.Flex.invokeProcedure(inspectionHistoryData, this.options).then((response) => {
      if (response.invocationResult.result && response.status === 200) {
        this.questions = response.invocationResult.result;

        this.questions = this.cleanQuestions(this.questions);

        this.lastRetrievedInspectionsId = inspectionsId;
        this.displayModal = true;
      }
    });    
  }

  cleanQuestions(questions: InspectionHistoryQuestionModel[]) {
    let questionObjectsWithNonBlankQuestions: InspectionHistoryQuestionModel[] = [];
    for (let question of questions) {
      if (question.QUESTION != null && question.QUESTION != '') {
        questionObjectsWithNonBlankQuestions.push(question);
      }
    }
  
    return questionObjectsWithNonBlankQuestions;
  }

    getLocomotiveString() {
      if (this.locomotiveDetail == null) {
        return "";
      }

      if (this.locomotiveDetail.locoInit && this.locomotiveDetail.locoInit != "" 
        && this.locomotiveDetail.locoNumber && this.locomotiveDetail.locoNumber != "") {
          return this.locomotiveDetail.locoInit.toLocaleUpperCase() + this.locomotiveDetail.locoNumber;
      }

    return "";
  }
}
