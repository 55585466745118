import { Component, OnInit } from '@angular/core';
import { SubmitInspectionAnswerDepartureTest } from '../../calendar-day-inspection/models/submit-inspection-answer.model';

@Component({
  selector: 'departure-test-pg2-test',
  templateUrl: './departure-test-pg2-test.component.html',
  styleUrls: ['./departure-test-pg2-test.component.scss']
})
export class DepartureTestPg2TestComponent implements OnInit {
  options: any[];
  inspectionAnswers: SubmitInspectionAnswerDepartureTest[] = new Array(6);
  kino = "no";
  
  LSLTest = false;
  goesThrough_ACSESTerritory = false;
  
  constructor() { }

  ngOnInit(): void {
    this.assignIdToAnswers();

    this.options = [
      { label: 'Yes', value: 'Y', icon: 'csx-common_complete' },
      { label: 'No', value: 'N', icon: 'csx-common_remove_circle' },
    ];
  }

  assignIdToAnswers() {
    this.inspectionAnswers[0] = {}; this.inspectionAnswers[0].id = 120; this.inspectionAnswers[0].category = 0;
    this.inspectionAnswers[0].answer = "";  this.inspectionAnswers[0].comment = "";

    this.inspectionAnswers[1] = {}; this.inspectionAnswers[1].id = 121; this.inspectionAnswers[1].category = 0;
    this.inspectionAnswers[1].answer = "";  this.inspectionAnswers[1].comment = "";

    this.inspectionAnswers[2] = {}; this.inspectionAnswers[2].id = 122; this.inspectionAnswers[2].category = 0;
    this.inspectionAnswers[2].answer = "";  this.inspectionAnswers[2].comment = "";

    this.inspectionAnswers[3] = {}; this.inspectionAnswers[3].id = 123; this.inspectionAnswers[3].category = 0;
    this.inspectionAnswers[3].answer = "";  this.inspectionAnswers[3].comment = "";

    this.inspectionAnswers[4] = {}; this.inspectionAnswers[4].id = 124; this.inspectionAnswers[4].category = 0;
    this.inspectionAnswers[4].answer = "";  this.inspectionAnswers[4].comment = "";

    this.inspectionAnswers[5] = {}; this.inspectionAnswers[5].id = 125; this.inspectionAnswers[5].category = 0;
    this.inspectionAnswers[5].answer = "";  this.inspectionAnswers[5].comment = "";

  }  

}
