import { RouterModule } from '@angular/router';
import { NotAuthorizedComponent } from 'csx-ng';
import { LocomotiveDetailsComponent } from './components/locomotive-details/locomotive-details.component';
import { CalendarDayInspectionComponent } from './components/calendar-day-inspection/calendar-day-inspection.component'
import { InspectionHistoryComponent } from './components/inspection-history/inspection-history.component';
import { AppLocInspComponent } from './components/app-loc-insp/app-loc-insp.component';
import { AppReportIncidentComponent } from './components/app-loc-insp/app-report-incident/app-report-incident.component';
import { RouteGuardService } from './services/guards/route-guard.service';
import { DepartureTestComponent } from './components/departure-test/departure-test.component';
import { WorkReportInspectionComponent } from './components/work-report-inspection/work-report-inspection.component';
export const routing = RouterModule.forRoot(
  [
    {
      path: '',
      component: LocomotiveDetailsComponent,
    },
    {
      path: 'loco',
      component: LocomotiveDetailsComponent,
      canActivate: [RouteGuardService]
    },
    {
      path: 'loco-insp',
      component: AppLocInspComponent,
      canActivate: [RouteGuardService]
    },
    {
      path: 'calendar-inspection',
      component: CalendarDayInspectionComponent,
      canActivate: [RouteGuardService]
    },
    {
      path: 'repo-inci',
      component : AppReportIncidentComponent,
      canActivate: [RouteGuardService]
    },{
      path: 'work-report-inspection',
      component : WorkReportInspectionComponent,
      canActivate: [RouteGuardService]
    },
    {
      path : 'inspectionhistory',
      component : InspectionHistoryComponent
    },
    {
      path : 'departuretest',
      component : DepartureTestComponent
    },    
    {
			path: 'notauthorized',
      component: NotAuthorizedComponent,
      data: {
        adminEmail: 'app-admin@csx.com'
      }
    }
  ],
  { useHash: true }
);
