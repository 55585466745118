import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HashLocationStrategy,
  Location,
  LocationStrategy
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

/* CSX-SPECIFIC */
import { SecurityConfigInjectionToken } from '@csx/security';
import { AppUser, CsxNgModule, CsxNgTemplatesModule } from 'csx-ng';
import { CsxFlexNgOidcModule, AuthService } from 'csx-flex-ng-oidc';

/* PRIME NG */
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';
import { AppLocInspComponent } from './components/app-loc-insp/app-loc-insp.component';
import { AppTemplateComponent } from './app-template/app-template.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import {InputNumberModule} from 'primeng/inputnumber';
import { SplitButtonModule } from 'primeng/splitbutton';

/* APP-SPECIFIC */
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { CalendarDayInspectionComponent } from './components/calendar-day-inspection/calendar-day-inspection.component';
import { WorkReportInspectionComponent } from './components/work-report-inspection/work-report-inspection.component';


import { QuestionDetailComponent } from './components/calendar-day-inspection/question-detail/question-detail.component';
import { QuestionSummaryComponent } from './components/calendar-day-inspection/question-summary/question-summary.component';
import { LocomotiveDetailsComponent } from './components/locomotive-details/locomotive-details.component';
import { AppLocInspService } from './services/app-loc-insp/app-loc-insp.service';
import { AppReportIncidentComponent } from './components/app-loc-insp/app-report-incident/app-report-incident.component';
import { AppProblemPopupComponent } from './components/app-loc-insp/app-report-incident/app-problem-popup/app-problem-popup.component';
import { InspectionHistoryComponent } from './components/inspection-history/inspection-history.component';
import { RouteGuardService } from '../app/services/guards/route-guard.service';
import { SecConfig } from './security/security.config';
import { WindowRef } from './windowref';
import { DepartureTestComponent } from './components/departure-test/departure-test.component';
import { DepartureTestPg1SetupComponent } from './components/departure-test/departure-test-pg1-setup/departure-test-pg1-setup.component';
import { DepartureTestPg2TestComponent } from './components/departure-test/departure-test-pg2-test/departure-test-pg2-test.component';
import { DepartureTestPg3RecordComponent } from './components/departure-test/departure-test-pg3-record/departure-test-pg3-record.component';
import { DepartureTestPg4ResultComponent } from './components/departure-test/departure-test-pg4-result/departure-test-pg4-result.component';
import { DepartureTestPg5FinalComponent } from './components/departure-test/departure-test-pg5-final/departure-test-pg5-final.component';
import { AppLocheaderInfoComponent } from './components/shared/app-locheader-info/app-locheader-info.component';

@NgModule({
  declarations: [
    AppComponent,
    AppTemplateComponent,
    AppLocInspComponent,
    LocomotiveDetailsComponent,
    CalendarDayInspectionComponent,
    QuestionDetailComponent,
    QuestionSummaryComponent,
    AppReportIncidentComponent,
    AppProblemPopupComponent,
    DepartureTestComponent,
    DepartureTestPg1SetupComponent,
    DepartureTestPg2TestComponent,
    DepartureTestPg3RecordComponent,
    DepartureTestPg4ResultComponent,
    InspectionHistoryComponent,
    DepartureTestPg5FinalComponent,
    AppLocheaderInfoComponent,
    InspectionHistoryComponent,
    WorkReportInspectionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    FormsModule,
    HttpClientModule,
    routing,
    CsxNgModule,
    CsxNgTemplatesModule,
    CsxFlexNgOidcModule.initialize(),
    MenubarModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
    DropdownModule,
    TableModule,
    InputTextModule,
    ProgressBarModule,
    SelectButtonModule,
    TabViewModule,
    TableModule,
    CalendarModule,
    AutoCompleteModule,
    DialogModule,
    ProgressSpinnerModule,
    InputSwitchModule,
    InputNumberModule,
    SplitButtonModule 
  ],
  providers: [
    AppUser,
    WindowRef,
    [
      Location,
      { provide: LocationStrategy, useClass: HashLocationStrategy },
      { provide: SecurityConfigInjectionToken, useValue: SecConfig }
    ],
    AppLocInspService,
    AppProblemPopupComponent,
    RouteGuardService,
    AuthService
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
