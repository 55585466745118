export const wrQuestions: any =
    [
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 1,
            "CLIP_QUESTIONSID": 1,
            "QUESTION": "Enter Train Number",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Train Number",
            "CATEGORYID": 1,
            "CSXCATEGORY" : 1,
            "CSXDESCRIPTION": "Train Info"
          
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 2,
            "CLIP_QUESTIONSID": 2,
            "QUESTION": "Disptached From",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Disptached From",
            "CATEGORYID": 1,
            "CSXCATEGORY" : 1,
            "CSXDESCRIPTION": "Train Info"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 3,
            "CLIP_QUESTIONSID": 3,
            "QUESTION": "Dispatch Date",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Dispatch Date",
            "CATEGORYID": 1,
            "CSXCATEGORY" : 1,
            "CSXDESCRIPTION": "Train Info"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 4,
            "CLIP_QUESTIONSID": 100,
            "QUESTION": "Bike Pipe Pressure",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Bike Pipe Pressure",
            "CATEGORYID": 2,
            "CSXCATEGORY" : 2,
            "CSXDESCRIPTION": "Pressure"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 5,
            "CLIP_QUESTIONSID": 101,
            "QUESTION": "Main Reservoir Pressure",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Main Reservoir Pressure",
            "CATEGORYID": 2,
            "CSXCATEGORY" : 2,
            "CSXDESCRIPTION": "Pressure"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 6,
            "CLIP_QUESTIONSID": 102,
            "QUESTION": "Present Condition",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Present Condition",
            "CATEGORYID": 3,
            "CSXCATEGORY" : 3,
            "CSXDESCRIPTION": "Radio"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 7,
            "CLIP_QUESTIONSID": 103,
            "QUESTION": "Serial Number",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Serial Number",
            "CATEGORYID": 3,
            "CSXCATEGORY" : 3,
            "CSXDESCRIPTION": "Radio"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 8,
            "CLIP_QUESTIONSID": 104,
            "QUESTION": "Unit Number",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Unit Number",
            "CATEGORYID": 4,
            "CSXCATEGORY" : 4,
            "CSXDESCRIPTION": "Speed Indicator"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 9,
            "CLIP_QUESTIONSID": 105,
            "QUESTION": "Condition",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Condition",
            "CATEGORYID": 4,
            "CSXCATEGORY" : 4,
            "CSXDESCRIPTION": "Speed Indicator"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 10,
            "CLIP_QUESTIONSID": 106,
            "QUESTION": "Miles off at:",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Miles off at:",
            "CATEGORYID": 4,
            "CSXCATEGORY" : 4,
            "CSXDESCRIPTION": "Speed Indicator"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 11,
            "CLIP_QUESTIONSID": 107,
            "QUESTION": "MPH:",
            "PROBLEMCODE": "",
            "DESCRIPTION": "MPH:",
            "CATEGORYID": 4,
            "CSXCATEGORY" : 4,
            "CSXDESCRIPTION": "Speed Indicator"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 12,
            "CLIP_QUESTIONSID": 108,
            "QUESTION": "Locomotive 1:",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Locomotive :",
            "CATEGORYID": 5,
            "CSXCATEGORY" : 5,
            "CSXDESCRIPTION": "Additional Locomotive? Optional"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 13,
            "CLIP_QUESTIONSID": 109,
            "QUESTION": "Locomotive 2:",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Locomotive :",
            "CATEGORYID": 5,
            "CSXCATEGORY" : 5,
            "CSXDESCRIPTION": "Additional Locomotive?  Optional"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 14,
            "CLIP_QUESTIONSID": 110,
            "QUESTION": "Locomotive 3:",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Locomotive :",
            "CATEGORYID": 5,
            "CSXCATEGORY" : 5,
            "CSXDESCRIPTION": "Additional Locomotive?  Optional"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 15,
            "CLIP_QUESTIONSID": 111,
            "QUESTION": "Locomotive 4:",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Locomotive :",
            "CATEGORYID": 5,
            "CSXCATEGORY" : 5,
            "CSXDESCRIPTION": "Additional Locomotive?  Optional"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 16,
            "CLIP_QUESTIONSID": 112,
            "QUESTION": "Locomotive 5:",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Locomotive :",
            "CATEGORYID": 5,
            "CSXCATEGORY" : 5,
            "CSXDESCRIPTION": "Additional Locomotive?  Optional"
        },
        {
            "INSPECTIONTYPEDESCR": "LOCOMOTIVE WORK REPORT - 5001B",
            "ISACTIVE": 1,
            "OUTOFSERVICE": 0,
            "PARTIALPOWER": 0,
            "TOSHOP": 0,
            "TRAILONLY": 0,
            "CLIP_INCIDENTCONFIGID": 17,
            "CLIP_QUESTIONSID": 113,
            "QUESTION": "Locomotive 6:",
            "PROBLEMCODE": "",
            "DESCRIPTION": "Locomotive :",
            "CATEGORYID": 5,
            "CSXCATEGORY" : 5,
            "CSXDESCRIPTION": "Additional Locomotive?  Optional"
        }
    ]
