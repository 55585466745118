import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, AuthStates, FlexService } from 'csx-flex-ng-oidc';
import { Subscription } from 'rxjs';
import { LcdiCategory } from './models/lcdi-category.model';
import { SubmitInspection } from './models/submit-inspection.model';
import { LcdiCategoriesService } from './services/lcdi-categories.service';
import { AppLocInspService } from '../../services/app-loc-insp/app-loc-insp.service';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'calendar-day-inspection',
  templateUrl: './calendar-day-inspection.component.html',
  styleUrls: ['./calendar-day-inspection.component.scss']
})
export class CalendarDayInspectionComponent implements OnInit {
  testQuestionsData: any[] = [];
  lcdiCategories: LcdiCategory[];
  imageUrls: any[];
  imagesSet: any[]=[];
  activeContainer: string;
  userId: string;
  location: string;
  // delivered params as part of route
  locoInit: string;
  locoNum: string;
  // control for success dialog
  displaySuccess: boolean;

  // need this to initialize AWS
  authSubscription: Subscription;
  paramSubscription: Subscription;
  formType: string='LOCOMOTIVE CALENDAR DAY INSPECTION - 5001A';

  constructor(
    private Flex: FlexService,
    private lcdiCategoriesService: LcdiCategoriesService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private appLocInspService: AppLocInspService
  ) {
    this.paramSubscription = this.route.queryParams.subscribe(params => {
      this.locoInit = params['locoInit'];
      this.locoNum = params['locoNum'];
      // force the component to reload if the path param is changed
      this.ngOnInit();
    });

    this.appLocInspService.locoLocation$.subscribe(val => {
      if(val) {
        this.location = val;       
      }
    });
  }

  ngOnInit(): void {
    this.activeContainer = 'default';
    this.userId = null;
    this.displaySuccess = false;
    // initialize AWS and call API maybe?
    this.initializeAndGetData();
  }

  categorySelected(newCategory: LcdiCategory): void {
    // changes input to summary that handles highlight in *ngFor
    this.activeContainer = newCategory.csxCategoryDescription;
  }

  onAnswerSelected(category: LcdiCategory): void {
    // update the correct answered question
    this.lcdiCategoriesService.updateAnswer(category);
  }

  onImageUpload(imageUrls: any[]):void{
    this.imagesSet.push(imageUrls);
    console.log('&&&&&&&&&&'+imageUrls[0]);
      }

  // FIXME this should be handled by the module it lives in and a route guard
  initializeAndGetData(): void {
    this.authSubscription = this.authService
      .isAuthorized()
      .subscribe(authStatus => {
        if (authStatus === AuthStates.AUTHORIZED) {
          if (this.Flex.user) {
            this.userId = this.Flex.user.id;
            this.getLcdiQuestions(this.formType);
          }
        }
      });
  }

  // function to call the flex service that invokes the API
  getLcdiQuestions(formType:string) {
    this.lcdiCategoriesService.getLcdiQuestions(formType).then(response => {
      if (
        response.invocationResult &&
        response.invocationResult.result &&
        response.status === 200
      ) {
        this.testQuestionsData = response.invocationResult.result;
        // build the stuff from the API call data
        this.lcdiCategoriesService.buildLcdiQuestionsAndCategories(
          this.testQuestionsData
        );
        this.lcdiCategoriesService.lcdiCategories$.subscribe(categories => {
          this.lcdiCategories = categories;
        });
      } else {
        // TODO implement failure path
        console.log('getLcdiQuestions() if trap');
      }
    }).catch(err => console.error('getLcdiQuestions: ', err));
  }

  returnToLocoInsp(): void {
    // Submission success
    this.appLocInspService.setBoolForSubmitCompletion(this.displaySuccess);
    // route back to loco-insp with same loco details
    this.router.navigate([
      '/loco-insp',
      { locoInit: this.locoInit, locoNum: this.locoNum }
    ]);
  }

  // TODO implmement this
  submitReport(categories: LcdiCategory[]): void {
    if (!this.disableSubmit(categories)) {
      // TODO figure out what exactly needs to be called with what
      this.loaderService.setLoading(true);
      this.submitInspection(categories);
    } else {
      // TODO give feedback for why?
      console.log('do nothing, the questions are incomplete');
    }
  }

  disableSubmit(categories: LcdiCategory[]): boolean {
    // logic is more straightforward if start under the assumption that it is enabled, negate before returning
    let enableSubmit: boolean = true;

    for (let category of categories) {
      for (let question of category.lcdiQuestions) {
        // no answer or the answer is no w/o a comment
        if (
          !question.answer ||
          (question.answer === 'N' && !question.answerComment)
        ) {
          enableSubmit = false;
        } else {
          // TODO implment failure if required
          console.log('disableSubmit() if trap');
        }
      }
    }
    // negate the enabled, as the button attribute is for disable
    return !enableSubmit;
  }

  submitInspection(categories: LcdiCategory[]): void {
    // generate the parameters to invoke the procedure
    console.log('&&**%%%'+this.imagesSet)
    let parameters = [
      [
         null,
         this.userId,
         (new Date()).toLocaleString(),
         this.locoInit + this.locoNum,
         null,
         this.location,
        (new Date()).toLocaleString(),
        1
    ]
    ];

    // define procedure invocation
    let submitInspectionSqlInvocation = {
      adapter: 'sql_clip',
      procedure: 'submitInspectionSql',
      parameters: parameters
    };

    // define invocation options
    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      }
    };

    // invoke it!
    this.Flex.invokeProcedure(submitInspectionSqlInvocation, options).then(
      response => {
        if (
          response.invocationResult &&
          response.invocationResult.result &&
          response.status === 200
        ) {
          let inspectionId =
            response.invocationResult.result[0].CLIP_INSPECTIONSID;
          this.callSubmitInspectionAnswersSqlProcedure(
            categories,
            inspectionId
          );
          this.submitImagesProcedure(inspectionId);
        } else {
          // TODO implement failure path
          this.loaderService.setLoading(false);
          console.log('submitInspection() if trap');
        }
      }
    );
  }

  callSubmitInspectionAnswersSqlProcedure(
    categories: LcdiCategory[],
    inspectionId: number
  ): void {
    let jsonParameters =
      this.lcdiCategoriesService.convertAnswersForProcedureInvocation(
        categories,
        inspectionId,
        this.userId
      );

        console.log(jsonParameters);

    let submitInspectionAnswersSqlInvocation = {
      adapter: 'sql_clip',
      procedure: 'submitInspectionAnswersSql',
      parameters: [JSON.stringify(jsonParameters)]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      }
    };

    this.Flex.invokeProcedure(
      submitInspectionAnswersSqlInvocation,
      options
    ).then(response => {
      if (
        response.invocationResult &&
        response.invocationResult.result &&
        response.status === 200
      ) {
        this.loaderService.setLoading(false);
        this.displaySuccess = true;
      } else {
        // FIXME implement failure path
        this.loaderService.setLoading(false);
        console.log('Implement behavior for failure submission', response);
      }
    });
  }

  submitImagesProcedure(this: this,inspectionId: any){

this.imagesSet.forEach(img=>this.submitImg(img,inspectionId));
    
  }

  submitImg(img: any[],inspectionId: any){
    this.appLocInspService.createImageEntryInMaximo(inspectionId,img[0],img[1],
    this.userId, img[2], 'CLIP_INSPECTIONS').then(
     result => {
       console.log(result);
     }
   ).catch(err => console.error('createImageForMaximo: ', err));
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }
}
