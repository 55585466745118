import { ChangeDetectorRef, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService, AuthStates, FlexService, User } from 'csx-flex-ng-oidc';
import { CSXHeaderModel } from 'csx-ng';
import { MenuItem } from 'primeng/api';
import { Subscription, Subject } from 'rxjs';
import { AnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { LocomotiveIncidentMap } from './app-loc-incident.map';
import { LocomotiveDetail } from './model/app-loc-insp-Locomotive';
import { AppLocInspService } from '../../services/app-loc-insp/app-loc-insp.service';

@Component({
  selector: 'app-loc-insp',
  templateUrl: './app-loc-insp.component.html',
  styleUrls: ['./app-loc-insp.component.scss']
})
export class AppLocInspComponent implements OnInit, OnDestroy {
  items: MenuItem[];
  headerModel: CSXHeaderModel;
  authSubscription: Subscription;
  showResults: boolean = false; // hidden
  locoInit: string;
  locoNumber: string;
  locoDivision: string;
  locoLocation: string;
  locomotiveAlerts: any;
  locomotiveAssets: any;
  msgInspectionDue: string;
  msgWorkReportDue: string;
  boolInspectionDue: boolean;
  boolWorkReportDue: boolean;
  boolAfterCalInspectSubmit: boolean;
  boolAfterWRInspectSubmit: boolean;
  locomotiveIncidents: any;
  routedTo: boolean = false;
  wrDueDate: string;
  csDueDate: string;

  public locoIncidents: Array<any> = [];
  locomotiveDetail: LocomotiveDetail;

  locoCalInspection : string = "5001A";  // LOCOMOTIVE CALENDAR DAY INSPECTION - 5001A
  locoWorkDue : string = "5001B";             // LOCOMOTIVE WORK REPORT - 5001B"

  constructor(
    appUser: User,
    private Flex: FlexService,
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private cd: ChangeDetectorRef,
    private appLocInspService: AppLocInspService
  ) {
    // authService.onUserUpdated.subscribe((user: User) => {
    //   this.headerModel.userName = user.displayName;
    //   this.headerModel.userRacf = user.racf;
    //   this.cd.detectChanges();
    // });
    // Default values for dev
    //this.locoInit = "CSXT";
    //this.locoNumber = "000001";
    this.locoDivision = 'FL';
    this.locoLocation = 'Jacksonville';

    // Messages
    this.msgInspectionDue =
      'Inspection is past due, Please complete the LCDI inspection';
    //this.msgWorkReportDue =
      //'Locomotive work report 5001B is past due, Please complete submit the report';

    // Boolean show/hide
    this.boolInspectionDue = false;
    this.boolWorkReportDue = false;
    this.boolAfterCalInspectSubmit = false;
    this.boolAfterWRInspectSubmit = false;
  }

  ngOnInit() {
    this.initializeAndLoadData();
    // this.appLocInspService.submitSuccess$.subscribe(val => {
    //   if(val) {
    //     this.boolAfterCalInspectSubmit = true;
    //     this.boolInspectionDue = false;
    //   }
    // });
    // this.appLocInspService.submitWRSuccess$.subscribe(val => {
    //   if(val) {
    //     this.boolAfterWRInspectSubmit = true;
    //     this.boolWorkReportDue = false;
    //   }
    // });
  }

  initializeAndLoadData() {
    this.authSubscription = this.authService
      .isAuthorized()
      .subscribe(authStatus => {
        if (authStatus === AuthStates.AUTHORIZED) {
          if (this.Flex.user) {
            this.appLocInspService.locoInfo$.subscribe(
              locomotiveDetail => (this.locomotiveDetail = locomotiveDetail)
            );

            if(this.locomotiveDetail === undefined || this.locomotiveDetail == null)
            {
              this.routeBackNoLocInformation();
            }


            this.locoInit = this.locomotiveDetail.locoInit;
            this.locoNumber = this.locomotiveDetail.locoNumber;
          }
        /*  this.route.paramMap.subscribe((params: ParamMap) => {
            this.locoInit = params.get('locoInit');
            this.locoNumber = params.get('locoNum');
            console.log(this.locoInit + ' ' + this.locoNumber);
          }); */
          if (this.locoInit != undefined && this.locoNumber != undefined)
            this.validateLoco();
          else
          {
            console.log("Sending back to enter loco details");
            this.routeBackNoLocInformation();
          }
        }
      });
  }

  ngOnDestroy() {
    console.log('ngOnDestroy: Unsubscribed to auth status ');
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  getDueDatesAndAlerts(locoInit, locoNum) {
    if (
      locoInit === undefined ||
      locoNum === undefined ||
      locoInit.length == 0 ||
      locoNum.length == 0
    ) {
      alert('Enter a loco Init and Number');
    } else {
      this.showResults = true;
    }
  }

  validateLoco() {
    var that = this; // Cannot debug easily without this/that ...
    this.getLocoAlerts(this.locoInit + this.locoNumber)
      .then(response => {
        if (response.invocationResult.result && response.status === 200) {
            var tmpLocoAlerts = JSON.stringify(
            response.invocationResult.result
          );
          try {
            that.locomotiveAlerts = JSON.parse(tmpLocoAlerts);
          }
          catch(err)
          {
            console.log("getLocoAlerts : " + err);
          }
        }
        if(that.locomotiveAlerts !== undefined)
        {
          that.locomotiveAlerts.forEach(lo => {
            console.log("Locomotive Alerts : " + lo);
            console.log("Inspection type description : " + lo.INSPECTIONTYPEDESCR);
            if(lo.INSPECTIONTYPEDESCR.includes(that.locoCalInspection))
            {
              if((that.getDateDiff(lo.CREATED) > 1 && that.boolAfterCalInspectSubmit != true) || !lo.CREATED)
                that.boolInspectionDue = true;
                else
                that.boolAfterCalInspectSubmit=true;

            }
            else if(lo.INSPECTIONTYPEDESCR.includes(that.locoWorkDue))
            {
              this.wrDueDate=lo.CREATED;
              console.log("work reportDate*******"+this.wrDueDate)
             // if((that.getDateDiff(lo.CREATED) > 1 && that.boolAfterWRInspectSubmit != true) || !lo.CREATED)
                that.boolWorkReportDue = true;
               // else 
               // that.boolAfterWRInspectSubmit=true;
            }else{
              this.csDueDate=lo.CREATED;
            }
          })
        }
      })
      .catch(err => console.error('getLocoAlerts: ', err));

    this.getLocoIncidents(this.locoInit + this.locoNumber)
      .then(response => {
        if (response.invocationResult.result && response.status === 200) {
          var tmpLocoInformation = JSON.stringify(
            response.invocationResult.result
          );
          this.locoIncidents = JSON.parse(tmpLocoInformation);
          this.locoIncidents.forEach(l => {
            var tmpDateDiff = this.getDateDiff(l.CSXOCCURDATE);
            l.DATEDIFF = tmpDateDiff;
            l.REDSUMMARY = LocomotiveIncidentMap.get(l.PROBLEMCODE);
          });
        }
      })
      .catch(err => console.error('getLocoIncidents: ', err));

    this.locoIncidents.forEach(l => {
      console.log(l.PROBLEMCODE);
      console.log(l.DESCRIPTION);
      console.log(l.CSXOCCURLOCATION);
      console.log(l.CSXOCCURDATE);
      console.log(l.REPORTEDBY);
      console.log(l.DATEDIFF);
    });

 /*   this.getLocationAssetDetails(this.locoInit + this.locoNumber)
      .then(response => {
        if (response.invocationResult.result && response.status === 200) {
          this.locomotiveAssets = JSON.stringify(
            response.invocationResult.result
          );
        }
      })
      .catch(err => console.error('getLocationAssetDetails: ', err)); */
  }

  getDateDiff(str) {
    return (
      ((Date.now().valueOf() - Date.parse(str).valueOf()) /
        (1000 * 3600 * 24)) >>
      0
    );
  }

  getLocoAlerts(locoId: string) {
    let locoAlertsData = {
      adapter: 'sql_clip',
      procedure: 'getAlertsSql',
      parameters: [locoId]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      },
      timeout: 120000
    };

    return this.Flex.invokeProcedure(locoAlertsData, options).then(function (
      response
    ) {
      console.log('getLocoAlerts: ', response);
      return response;
    });
  }

  getLocoIncidents(locoId: string) {
    let locoIncidentsData = {
      adapter: 'sql_clip',
      procedure: 'getIncProblemCodesSql',
      parameters: [locoId]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      },
      timeout: 120000
    };

    return this.Flex.invokeProcedure(locoIncidentsData, options).then(function (
      response
    ) {
      console.log('getLocoIncidents: ', response);
      return response;
    });
  }
/*
  getLocationAssetDetails(locoId: string) {
    let locationAssetDetails = {
      adapter: 'sql_clip',
      procedure: 'getAssetNumSql',
      parameters: [locoId]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      },
      timeout: 120000
    };

    return this.Flex.invokeProcedure(locationAssetDetails, options).then(
      function (response) {
        console.log('getLocationAssetDetails: ', response);
        return response;
      }
    );
  }
*/
  loadReportForm() {
    this.router.navigate(['/repo-inci']);
  }

  loadWorkReportForm(){
    this.router.navigate(['/work-report-inspection']);
  }
  routeToCalendarInspection(locoInit: string, locoNum: string): void {
    console.log('route to the calendar day inspection with loco details');
    this.router.navigate(['/calendar-inspection'], {
      queryParams: { locoInit, locoNum }
    });
  }

  routeBackNoLocInformation()
  {
     // Either refreshed or loco not entered
     this.router.navigate([
      '/loco'
    ]);
  }
}
