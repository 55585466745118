import { Component, OnInit } from '@angular/core';
import { FlexService, AuthService, AuthStates } from 'csx-flex-ng-oidc';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { AppLocInspService } from '../../../services/app-loc-insp/app-loc-insp.service';
import { LocomotiveDetail } from '../../app-loc-insp/model/app-loc-insp-Locomotive';

@Component({
  selector: 'app-locheader-info',
  templateUrl: './app-locheader-info.component.html',
  styleUrls: ['./app-locheader-info.component.scss']
})
export class AppLocheaderInfoComponent implements OnInit {
  locomotiveDetail: LocomotiveDetail;
  locoInit: string;
  locoNumber: string;
  locoId: string;
  locoDivision: string;
  locoLocation: string;
  poweredAxles: string;
  dynamicBrakingAxles: string;
  locomotiveAssets: any;
  locoClass: string;
  currentDate: string;
  incidentNumber: string;
  snapshot: RouterStateSnapshot;
  boolLocoInspection: boolean = false;
  boolReportInspection: boolean = false;
  boolAllOthers: boolean = false;

  constructor(private Flex: FlexService,
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private appLocInspService: AppLocInspService) {
    this.snapshot = router.routerState.snapshot;
  }

  ngOnInit(): void {
    // For debug
    let that = this;
    this.authService
      .isAuthorized()
      .subscribe(authStatus => {
        if (authStatus === AuthStates.AUTHORIZED) {
          if (this.Flex.user) {
            this.appLocInspService.locoInfo$.subscribe(
              locomotiveDetail => (this.locomotiveDetail = locomotiveDetail)
            );
            if(this.locomotiveDetail !== undefined)
            {
              //this.locoId = "CSXT000026";
              this.locoInit = this.locomotiveDetail.locoInit;
              this.locoNumber = this.locomotiveDetail.locoNumber;
              this.locoId = this.locoInit + this.locoNumber;
            }
            this.appLocInspService.getLocationAssetDetails(this.locoId)
              .then(response => {
                if (response.invocationResult.result && response.status === 200) {
                  that.locomotiveAssets = JSON.stringify(
                    response.invocationResult.result
                  );
                  if(that.locomotiveAssets !== undefined)
                  {
                    try {
                      let tmp = JSON.parse(that.locomotiveAssets);
                      console.log(tmp);
                      this.locoLocation = tmp[0].CITY;
                      this.locoDivision = tmp[0].DIVISION;
                      this.locoClass    = tmp[0].CLASS;
                      this.currentDate  = tmp[0].TODAY;
                      this.appLocInspService.setLocation(this.locoLocation);
                      this.appLocInspService.setDivision(this.locoDivision);
                      this.poweredAxles = tmp[0].POWEREDAXLE;
                      this.dynamicBrakingAxles = tmp[0].DYNAMICBRAKINGAXLE;
                  } catch(e) {
                      console.log("Exception in parse " + e);
                  }

                  }
                }
              })
              .catch(err => console.error('getLocationAssetDetails: ', err));
          }
        }
      });

    // Hard Coded
    /*
    this.locoInit = 'CSXT';
    this.locoNumber = '000001';
    this.locoId = this.locoInit + this.locoNumber;
    this.locoLocation = "Greenville,NC";
    this.locoDivision = "FL";*/
    //this.currentDate = new Date().toString();
    console.log('^^^^^^^^^^'+this.currentDate);
    this.incidentNumber = "119517805";

    /*
        console.log("Route is : " + this.route.outlet + " " + this.route.component);
        console.log("Auth user is : " + this.authService.getAuthenticatedUser());
        console.log("app-loc-header Router state is : " + this.snapshot.url);
    */
    if (this.snapshot.url.includes('loco-insp')) {
      this.boolLocoInspection = true;
      this.boolReportInspection = false;
    }
    else if (this.snapshot.url.includes('repo-inci')) {
      this.boolLocoInspection = false;
      this.boolReportInspection = true;
    }
    else
    {
      // All others
      this.boolLocoInspection = false;
      this.boolReportInspection = false;
      this.boolAllOthers = true;
    }
  }


}
