import { Component, OnInit } from '@angular/core';
import { ReportIncident } from '../model/app-report-incident.model';
import { User, AuthService, FlexService } from 'csx-flex-ng-oidc';
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { DropdownItem } from 'primeng/dropdown';
import { AppLocInspService } from '../../../services/app-loc-insp/app-loc-insp.service';
import { LocomotiveDetail } from '../model/app-loc-insp-Locomotive';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader/loader.service';

declare const navigator: any;

interface FileInfo {
  file: File;
  userId: string;
}

@Component({
  selector: 'app-report-incident',
  templateUrl: './app-report-incident.component.html',
  styleUrls: ['./app-report-incident.component.scss']
})

export class AppReportIncidentComponent implements OnInit {

  reportIncident: ReportIncident;
  submitted: boolean;
  incidentForm: FormGroup;
  incidentNumber: string;
  locoLocation: string;
  locoDivision: string;
  currentDate: string;
  selectDate: Date;
  photoSource: any[];
  selectedPhotoSource: DropdownItem;
  userInfoForReport: string;

  locomotiveDetail: LocomotiveDetail;
  locoInit: string;
  locoNumber: string;
  public presignedUrl: string;
  imageUrls: any[] = [];

  // incidentDt format: MM/DD/YYYY, HH:MI:SS AM
  // Hard Coded for testing
  incidentDt: string = "01/14/2022, 10:10:10 AM";
  userId: string = "E8905";
  problemCode: string = "CSD";
  problemDetails: string = "test";

  public showProblemCode: boolean;
  isReadyForSubmit: boolean;
  submissionSuccess: boolean = false;
  showSelected: string;
  fileInfo: FileInfo;
  location: string;
  division: string;

  constructor(
    public appUser: User,
    private Flex: FlexService,
    public fb: FormBuilder,
    private appLocInspService: AppLocInspService,
    private loaderService: LoaderService,
    private router: Router) {
     
    this.photoSource = [
      {
        label: "From Camera",
        icon: 'pi pi-camera', command: () => {
          this.cameraClick();
      }
      },
      {
        label: "Photo Library",
        icon: 'pi pi-images', command: () => {
          this.clickFileUpload();
        }
      }
    ];
    this.fileInfo = { file: null, userId: "" };
  }

  ngOnInit(): void {
    this.submitted = false;
    this.showProblemCode = false;
    this.isReadyForSubmit = false;
    this.currentDate = new Date().toString();
    this.userId = this.Flex.user.id;

    this.userInfoForReport = this.Flex.user.id + " , " + this.Flex.user.firstName + " " + this.Flex.user.lastName;

    this.appLocInspService.locoInfo$.subscribe(locomotiveDetail => this.locomotiveDetail = locomotiveDetail);
    this.locoInit = this.locomotiveDetail.locoInit;
    this.locoNumber = this.locomotiveDetail.locoNumber;
    this.appLocInspService.locoLocation$.subscribe(val=>{
      this.location=val;
    });
    this.appLocInspService.locoDivision$.subscribe(val=>{
      this.division=val;
    });


    this.newIncident();
    // Hard Coded
    this.incidentNumber = "119517805";
    //   this.locoLocation = "Greenville,NC";
    //   this.locoDivision = "FL";

    this.incidentForm = this.fb.group({
      problemCode: new FormControl("", [
        Validators.required,
        Validators.minLength(1)
      ]),
      problemDetail: new FormControl("", [
        Validators.required,
        Validators.minLength(1)
      ]),
      incidentDate: new FormControl("", [
        Validators.required,
        Validators.minLength(8)
      ]),
    });
    this.showSelected = "Select Problem Code";

    // For file details
    this.fileInfo.userId = this.Flex.user.id;
  }

  OnSubmit() {
    this.loaderService.setLoading(true);
    var formType: string = 'CLIP_STAGINCIDENT';
    this.postLocoIncidents(this.reportIncident.locoID, this.reportIncident.incidentDate, this.reportIncident.userID
      , this.reportIncident.problemCode, this.reportIncident.problemDetails).then(
        response => {
          if (response.invocationResult.result && response.status === 200) {
            var tmpLocoInformation = JSON.stringify(response.invocationResult.result);
            var incidentId = response.invocationResult.result[0].CODE;
            this.submitImagesProcedure(incidentId);
            this.loaderService.setLoading(false);
            this.submissionSuccess = true;
          }
        }
      ).catch(err => (console.error("postLocoIncidents: ", err)));

  }


  submitImagesProcedure(this: this, inspectionId: any) {

    this.imageUrls.forEach(img => this.submitImg(img, inspectionId));

  }

  submitImg(img: any[], inspectionId: any) {
    var formType: string = 'CLIP_STAGINCIDENT';
    this.appLocInspService.createImageEntryInMaximo(inspectionId, '', img[1],
      this.userId, img[0], formType).then(
        result => {
          console.log(result);
        }
      ).catch(err => console.error('createImageForMaximo: ', err));
  }

  newIncident() {
    this.reportIncident = new ReportIncident();
    this.reportIncident.userID = this.Flex.user.id;
    this.reportIncident.locoID = this.locoInit + this.locoNumber; //"CSXT0000045";
  }

  postLocoIncidents(locoId: string, incidentDt: string, userId: string, problemCode: string, problemDetails: string) {
    let createStagingIncidentParms = {
      adapter: "sql_clip",
      procedure: 'createStagingIncidentSql',
      parameters: [[locoId, incidentDt, userId, problemCode, problemDetails]]
    };

    let options = {
      onSuccess: function (response) {
        return response;
      },
      onFailure: function (response) {
        return response;
      },
      timeout: 120000
    };

    return this.Flex.invokeProcedure(createStagingIncidentParms, options).then(function (response) {
      console.log("postLocoIncidents response: ", response);
      return response;
    });
  }
 

  clickFileUpload() {
    document.getElementById('fileUpload').click();
    this.loaderService.setLoading(true);
  }

  getFilePath(e) {
    console.log('Chosen file : ' + e.currentTarget.files[0].name);
    // Send to AWS S3
    this.fileInfo.file = e.currentTarget.files[0];
    this.sendFileToAWS(e.currentTarget.files[0].name);
  }

  openModal() {
    this.showProblemCode = true;
  }

  parentListener(pCode) {
    if (pCode !== undefined) {
      this.showSelected = pCode.code + "," + pCode.desc;
      this.reportIncident.problemCode = pCode.code;
      this.isReadyForSubmit = true; // Based on mockup
    }

  }

  get self(): AppReportIncidentComponent {
    return this;
  }

  cameraClick() {
    var instance = this;
    var photo;
    return new Promise(function (resolve, reject) {
      try {
        if (navigator.camera == undefined) {
          alert("navigator.camera == undefined");
        } else {
          navigator.camera.getPicture(
            (imageUri) => {
              photo = imageUri;
              instance.fileInfo.file = photo;
              let tmpFileName = instance.appLocInspService.locomotiveDetail.locoInit+instance.appLocInspService.locomotiveDetail.locoNumber + new Date().toString();
              this.sendFileToAWS(tmpFileName);
            },
            (error) => {
              alert("Unable to obtain picture: " + error);
            },
            {
              quality: 50,
              destinationType: navigator.camera.DestinationType.DATA_URL
            }
          )
        }
      } catch (e) {
        alert(e);
      }
    });
  }

  sendFileToAWS(fileName: string) {
    const formData: FormData = new FormData();
    var rescCtype: any;

    formData.append("file", this.fileInfo.file, fileName);
    this.appLocInspService.uploadFileForReportIncident(formData, fileName).subscribe(response => {
      console.log(response);
      // below hard coded incident number and question id, has to be handled //
      this.presignedUrl = response.presignedurl;
      var images = [fileName, this.presignedUrl];
      this.imageUrls.push(images);
      this.loaderService.setLoading(false);
    }, (err) => {console.log(err);
    this.loaderService.setLoading(false);}
    );
  }

  negateUrlString(url: string): string {
    var newstr = url.replace(/&/g, "\\'||chr(38)||\\'");
    console.log(newstr);
    return newstr;
  }

  getDateTime($event) {
    if (this.selectDate !== undefined) {
      this.reportIncident.incidentDate = this.formatDate(this.selectDate);
    }
  }

  cancel() {
    this.returnToLocoInsp();
  }

  formatDate(dateVal) {
    var newDate = new Date(dateVal);

    var sMonth = this.padValue(newDate.getMonth() + 1);
    var sDay = this.padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = new Date().getHours().toString();
    var sMinute = this.padValue(new Date().getMinutes());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = (iHourCheck - 12).toString();
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }

    sHour = this.padValue(sHour);

    return sMonth + "/" + sDay + "/" + sYear + ", " + sHour + ":" + sMinute + ":00 " + sAMPM;
  }

  padValue(value) {
    return (value < 10) ? "0" + value : value;
  }

  // Submission success
  returnToLocoInsp(): void {
    this.router.navigate([
      '/loco-insp'
    ]);
  }

}
